<template>
  <div class="mc-password-input" :class="cssFieldElementClass">
    <input
      :type="setType"
      :required="required"
      class="mc-text-input mc-password-input__control"
      :class="setInputClasses"
      :aria-invalid="isInvalid"
    />

    <button
      ref="button"
      type="button"
      class="mc-password-input__button"
      role="switch"
      :aria-pressed="setAriaPressed"
      @click="onClick"
    >
      {{ setButtonLabel }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'MPasswordInput',

  inject: {
    cssFieldElementClass: {
      default: '',
    },
  },

  props: {
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: Object,
      default: function () {
        return { show: 'Show', hide: 'Hide' };
      },
    },
  },

  data: function () {
    return {
      isVisible: false,
    };
  },

  computed: {
    setType() {
      return this.isVisible ? 'text' : 'password';
    },
    setInputClasses() {
      return {
        'is-valid': this.isValid,
        'is-invalid': this.isInvalid,
      };
    },
    setAriaPressed() {
      return this.isVisible ? 'true' : 'false';
    },
    setButtonLabel() {
      return this.isVisible ? this.buttonLabel.hide : this.buttonLabel.show;
    },
  },

  methods: {
    onClick() {
      const aria = this.$refs.button.getAttribute('aria-pressed') === 'false';
      this.isVisible = aria;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.text-input';
@import 'components/_c.password-input';
</style>
