<template>
  <m-icon
    :id="`mc-text-input__icon-${id}`"
    key="text-input-icon"
    class="mc-left-icon-input__icon"
    :name="icon"
  />
</template>

<script>
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MTextInputIcon',

  components: {
    MIcon,
  },

  props: {
    icon: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      id: null,
    };
  },

  created() {
    this.id = this._uid;
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.left-icon-input';
</style>
