<template>
  <textarea
    class="mc-textarea"
    :class="[
      { 'is-valid': isValid, 'is-invalid': isInvalid },
      cssFieldElementClass,
    ]"
    :aria-invalid="isInvalid"
    :value="value"
    v-on="inputListeners"
  />
</template>

<script>
export default {
  name: 'MTextArea',
  inject: {
    cssFieldElementClass: {
      default: '',
    },
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputListeners: function () {
      // see => https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
      var vm = this;
      return Object.assign({}, this.$listeners, {
        input: function (event) {
          vm.$emit('input', event.target.value);
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.textarea';
</style>
