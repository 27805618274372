<template>
  <div class="mc-accordion" :class="{ 'mc-accordion--s': size === 's' }">
    <input
      :id="id"
      ref="checkbox"
      type="checkbox"
      class="mc-accordion__trigger"
      :disabled="disabled"
      :checked="open"
      @change="$emit('update:open', $event.target.checked)"
    />
    <div
      :class="['mc-accordion__header', setHeaderClasses]"
      :aria-expanded="open"
      @click="$refs.checkbox.click()"
    >
      <slot name="beforeLabel" />
      <label
        :for="id"
        :aria-expanded="open"
        :class="['mc-accordion__header__label', setLabelClasses]"
        @click.stop
      >
        <m-icon v-if="icon" :name="icon" class="mc-accordion__icon" />
        {{ label }}
      </label>
      <slot name="afterLabel" />
    </div>
    <div class="mc-accordion__content">
      <slot />
    </div>
  </div>
</template>

<script>
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MAccordion',

  components: {
    MIcon,
  },

  model: {
    prop: 'open',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
      validator: (value) => ['s', 'm'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },

  computed: {
    setHeaderClasses() {
      return {
        'mc-accordion__label': true,
        'mc-accordion__label--icon': this.icon,
        'mc-accordion__label--no-padding': !this.padding,
      };
    },

    setLabelClasses() {
      return {
        'mc-accordion__header__label--with-before-slot':
          this.$slots.beforeLabel,
        'mc-accordion__header__label--with-after-slot': this.$slots.afterLabel,
      };
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/c.accordion';

.mc-accordion {
  &__header {
    display: flex;
    align-items: center;

    &__label {
      align-items: center;
      display: flex;
      cursor: pointer;
      gap: $mu050;
      white-space: nowrap;

      &--with-before-slot {
        padding-left: $mu100;
      }

      &--with-after-slot {
        padding-right: $mu100;
      }
    }
  }
}
</style>
