<template>
  <div
    v-if="icon"
    key="icon-input"
    class="mc-left-icon-input"
    :class="cssFieldElementClass"
  >
    <m-text-input-icon :icon="icon" />
    <m-text-input-field
      ref="mField"
      v-bind="[$attrs, $props]"
      :class="textInputFieldClass"
      v-on="$listeners"
    />
  </div>

  <m-text-input-field
    v-else
    key="input-only"
    ref="mField"
    v-bind="[$attrs, $props]"
    :class="cssFieldElementClass"
    v-on="$listeners"
  />
</template>

<script>
import MTextInputField from './MTextInputField.vue';
import MTextInputIcon from './MTextInputIcon.vue';

export default {
  name: 'MTextInput',

  components: {
    MTextInputField,
    MTextInputIcon,
  },

  inject: {
    cssFieldElementClass: {
      default: '',
    },
  },

  inheritAttrs: false,

  props: {
    icon: {
      type: String,
      default: null,
    },
    textInputFieldClass: {
      type: String,
      default: null,
    },
  },
};
</script>
