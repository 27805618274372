import MListBox from './MListBox.vue';
import MListBoxActions from './MListBoxActions.vue';

MListBox.install = function (Vue) {
  Vue.component(MListBox.name, MListBox);
};

MListBoxActions.install = function (Vue) {
  Vue.component(MListBoxActions.name, MListBoxActions);
};

export { MListBox, MListBoxActions };
