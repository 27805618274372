var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"mc-stepper",class:{
    'mc-stepper--compact': _vm.compact,
    'mc-stepper--shrinked': _vm.steps.length > 3,
  },attrs:{"aria-label":_vm.accessibilityLabels.stepperDescription}},[_c('ol',{staticClass:"mc-stepper__list"},_vm._l((_vm.steps),function(step,idx){return _c('li',{key:`mc-stepper__item-${idx}`,staticClass:"mc-stepper__item",class:{
        'mc-stepper__item--validated': _vm.isStepValidated(idx),
        'mc-stepper__item--current': step.isCurrent,
      },style:(`--steps: ${_vm.steps.length}; --current: ${idx + 1};`),attrs:{"aria-current":step.isCurrent ? 'step' : false,"aria-label":_vm.stepDescription(step, idx)},on:{"click":function($event){_vm.isStepValidated(idx) && _vm.$emit('step-changed', step)}}},[(step.href && _vm.isStepValidated(idx))?_c('a',{staticClass:"mc-stepper__link",attrs:{"href":step.href}},[_c('div',{staticClass:"mc-stepper__indicator",attrs:{"aria-hidden":"true"}},[(_vm.isStepValidated(idx))?_c('m-icon',{staticClass:"mc-stepper__icon",attrs:{"name":"NotificationAvailable16"}}):(step.isCurrent)?_c('span',[_vm._v(_vm._s(idx + 1))]):_vm._e()],1),_c('div',{staticClass:"mc-stepper__detail"},[_c('span',{staticClass:"mc-stepper__title"},[_vm._v(_vm._s(idx + 1)+" / "+_vm._s(_vm.steps.length))]),_c('span',{staticClass:"mc-stepper__label"},[_vm._v(_vm._s(step.label))])])]):[_c('div',{staticClass:"mc-stepper__indicator",attrs:{"aria-hidden":"true"}},[(_vm.isStepValidated(idx))?_c('m-icon',{staticClass:"mc-stepper__icon",attrs:{"name":"NotificationAvailable16"}}):(step.isCurrent)?_c('span',[_vm._v(_vm._s(idx + 1))]):_vm._e()],1),_c('div',{staticClass:"mc-stepper__detail"},[_c('span',{staticClass:"mc-stepper__title"},[_vm._v(_vm._s(idx + 1)+" / "+_vm._s(_vm.steps.length))]),_c('span',{staticClass:"mc-stepper__label"},[_vm._v(_vm._s(step.label))])])]],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }