<template>
  <div class="mc-radio" :class="rootClass">
    <input
      :id="id"
      type="radio"
      class="mc-radio__input"
      :class="{ 'is-invalid': isInvalid }"
      v-bind="$attrs"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
    />
    <label :for="id" class="mc-radio__label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'MRadio',

  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    rootClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.radio';
</style>
