import { render, staticRenderFns } from "./MCheckboxGroup.vue?vue&type=template&id=2ef3bca1&"
import script from "./MCheckboxGroup.vue?vue&type=script&lang=js&"
export * from "./MCheckboxGroup.vue?vue&type=script&lang=js&"
import style0 from "./MCheckboxGroup.vue?vue&type=style&index=0&id=2ef3bca1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports