<template>
  <input
    :type="type"
    :required="required"
    class="mc-text-input"
    :class="setClasses"
    :aria-invalid="isInvalid"
    :value="value"
    v-on="inputListeners"
  />
</template>

<script>
import {
  responsiveModifiers,
  responsiveModifierValidators,
} from '../../utils/mozaicClasses';

const supportedTypes = [
  'date',
  'email',
  'number',
  'password',
  'tel',
  'text',
  'time',
  'search',
];

export default {
  name: 'MTextInputField',

  props: {
    type: {
      type: String,
      default: 'text',
      validator: (type) => supportedTypes.includes(type),
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) => responsiveModifierValidators(value, ['s', 'm']),
    },
  },

  computed: {
    setClasses() {
      const classes = [];

      if (this.isValid) {
        classes.push('is-valid');
      }

      if (this.isInvalid) {
        classes.push('is-invalid');
      }

      if (this.icon) {
        classes.push('mc-left-icon-input__input');
      }

      if (this.size) {
        responsiveModifiers('mc-text-input', this.size, classes);
      }

      return classes;
    },
    inputListeners: function () {
      // see => https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
      var vm = this;
      return Object.assign({}, this.$listeners, {
        input: function (event) {
          vm.$emit('input', event.target.value);
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.text-input';
</style>
