<template>
  <component :is="htmlTag" class="mc-flag" :class="setClasses">
    <span class="mc-flag__label">{{ label }}</span>
  </component>
</template>

<script>
import { checkThemeValue } from '../../utils/theme.validator';

export default {
  name: 'MFlag',

  props: {
    label: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: null,
      validator: (value) => checkThemeValue(value),
    },
    htmlTag: {
      type: String,
      default: 'div',
    },
  },

  computed: {
    setClasses() {
      const classes = [];

      if (this.theme) {
        classes.push(`mc-flag--${this.theme}`);
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.flag';
</style>
