<template>
  <div v-click-outside="onClickOutside" class="mc-listbox-options">
    <button
      type="button"
      class="mc-listbox-options__trigger"
      @click="isOpen = !isOpen"
    >
      <m-icon name="DisplayOptions24" />
      <span class="mc-listbox-options__trigger-label">{{ triggerLabel }}</span>
    </button>
    <div
      ref="listbox"
      class="mc-listbox-options__container"
      :class="{ 'is-open': isOpen, 'align-right': position == 'right' }"
      role="listbox"
      aria-labelledby="listbox"
    >
      <ul
        v-for="(list, i) in listItems"
        :key="`list${i}`"
        class="mc-listbox-options__list"
      >
        <li
          v-for="(item, j) in list"
          :key="`item${j}`"
          class="mc-listbox-options__tile"
          :class="{ 'is-disabled': item.disabled }"
        >
          <m-icon
            v-if="item.icon"
            :name="item.icon"
            class="mc-listbox-options__icon"
            :color="item.danger ? '#C61112' : '#71706B'"
          />
          <component
            :is="item.href ? 'a' : 'button'"
            :href="item.href ? item.href : null"
            :type="item.href ? null : 'button'"
            :disabled="item.disabled ? true : null"
            class="mc-listbox-options__item"
            :class="{ 'is-danger': item.danger, 'is-disabled': item.disabled }"
            @click.self="onClickItem(item, i, j)"
          >
            {{ item.text }}
          </component>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MListBoxActions',

  components: {
    MIcon,
  },

  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
    },
    items: {
      type: Array,
      default: () => [],
    },
    triggerLabel: {
      type: String,
      default: 'Display options',
    },
  },

  data() {
    return {
      isOpen: this.open,
    };
  },

  computed: {
    listItems: function () {
      const hasNestedArray = this.items.filter(Array.isArray).length;

      if (hasNestedArray === 0) {
        const listItems = [];
        listItems.push(this.items);
        return [this.items];
      }

      return this.items;
    },
  },

  methods: {
    onClickOutside() {
      this.isOpen = false;
    },
    onClickItem(item, listIndex, itemIndex) {
      const valToEmit = Object.assign(
        { listIndex: listIndex, itemIndex: itemIndex },
        item
      );
      this.$emit('update:itemSelected', valToEmit);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';

.mc-listbox-options {
  display: inline-block;
  position: relative;

  &__trigger {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: $mu150;
    justify-content: center;
    padding: 0;
    width: $mu150;

    &-label {
      @include visually-hidden();
    }
  }

  &__container {
    position: absolute;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    min-width: $mu800;
    background-color: $color-grey-000;
    border: 1px solid $color-grey-600;
    border-radius: 3px;

    &.is-open {
      opacity: 1;
      visibility: visible;
      z-index: 11;
    }

    &.align-right {
      transform: translateX(calc(-100% + #{$mu150}));
    }
  }

  &__list {
    $parent: get-parent-selector(&);
    @include unstyle-list();
    margin: 0 auto;
    padding: 8px 0;

    &::-webkit-scrollbar {
      background-color: $color-grey-100;
      width: $mu025;

      &-thumb {
        background: $color-grey-600;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #bab6bc;
    }
  }

  &__tile {
    align-items: center;
    color: #1e1e1c;
    display: flex;
    gap: $mu050;
    min-height: $mu250;
    padding-left: $mu075;
    padding-right: $mu075;
    position: relative;

    &:hover {
      background-color: #eeedea;
    }

    &.is-disabled {
      background-color: $color-grey-200;

      &,
      .mc-listbox-options__item {
        color: $color-grey-600;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  &__item {
    @include set-font-scale('05', 'm');

    background: none;
    border: none;
    color: currentColor;
    cursor: pointer;
    padding: 0;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 2;
    }

    &,
    &:active,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &.is-danger {
      color: #c61112;
    }
  }
}
</style>
