<template>
  <div v-if="hasLabel" class="mc-checkbox">
    <input
      v-bind="$attrs"
      :id="id"
      ref="input"
      :name="name"
      type="checkbox"
      class="mc-checkbox__input"
      :class="setInputClasses"
      :checked="checked"
      :required="required"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
    />
    <label :for="id" class="mc-checkbox__label">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>

  <input
    v-else
    v-bind="$attrs"
    :id="id"
    ref="input"
    :name="name"
    type="checkbox"
    class="mc-checkbox__input"
    :class="setInputClasses"
    :checked="checked"
    :required="required"
    :disabled="disabled"
    @change="$emit('change', $event.target.checked)"
  />
</template>

<script>
export default {
  name: 'MCheckbox',

  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasLabel() {
      return this.label != null || this.$slots.label != null;
    },

    setInputClasses() {
      return {
        'is-invalid': this.isInvalid,
      };
    },
  },

  watch: {
    indeterminate() {
      this.setIndeterminate();
    },
  },

  mounted() {
    this.setIndeterminate();
  },

  methods: {
    setIndeterminate() {
      this.$refs.input.indeterminate = this.indeterminate;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/c.checkbox';
</style>
