<template>
  <component
    :is="htmlTag"
    class="ml-container"
    :class="{ 'ml-container--fluid': fluid }"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'MContainer',

  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    htmlTag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'layouts/l.container';
@import 'utilities/u.margin';
@import 'utilities/u.padding';
</style>
