import MCheckbox from './MCheckbox.vue';
import MCheckboxGroup from './MCheckboxGroup.vue';

MCheckbox.install = function (Vue) {
  Vue.component(MCheckbox.name, MCheckbox);
};

MCheckboxGroup.install = function (Vue) {
  Vue.component(MCheckboxGroup.name, MCheckboxGroup);
};

export { MCheckbox, MCheckboxGroup };
