<template>
  <div class="mc-fileuploader" :class="cssFieldElementClass">
    <input
      :id="id"
      ref="fileUpload"
      type="file"
      class="mc-fileuploader__input"
      :accept="accept"
      :multiple="multiple"
      :disabled="disabled"
      @change="onChange"
    />
    <label :for="id" class="mc-fileuploader__label">
      <span class="mc-fileuploader__label--center">
        {{ label }}
      </span>
    </label>
    <m-file-result
      v-if="displayFilesList && files.length > 0"
      :files="files"
      :allowed-extensions="allowedExtensions"
      :max-size="maxSize"
      :remove-label="removeLabel"
      @file-removed="deleteFile"
      @has-invalid-files="hasInvalidFiles"
    >
      <template v-for="(index, name) in $slots" #[name]>
        <slot :name="name" />
      </template>
    </m-file-result>
    <m-file-result
      v-if="uploadedFiles.length > 0"
      :files="uploadedFiles"
      :allowed-extensions="allowedExtensions"
      :remove-label="removeLabel"
      :disabled="disabled"
      @file-removed="deleteRemoteFile"
    />
  </div>
</template>

<script>
import MFileResult from './MFileResult.vue';
export default {
  name: 'MFileUploader',
  components: {
    MFileResult,
  },
  inject: {
    cssFieldElementClass: {
      default: '',
    },
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: 'mc-fileuploader',
    },
    label: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowedExtensions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    maxSize: {
      type: Number,
      default: null,
    },
    displayFilesList: {
      type: Boolean,
      default: true,
    },
    uploadedFiles: {
      type: [Array, Object],
      default: function () {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    removeLabel: {
      type: String,
      default: 'Remove',
    },
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    onChange(e) {
      this.files = [...e.target.files];
      this.$emit('file-added', this.files);
      e.target.value = '';
    },
    removeFromArray(fileList, value) {
      const array = Array.from(fileList);
      const idx = array.indexOf(value);
      if (idx !== -1) {
        array.splice(idx, 1);
      }
      return array;
    },
    deleteFile(file) {
      this.files = this.removeFromArray(this.files, file);
      this.$emit('file-removed', file);
    },
    deleteRemoteFile(file) {
      this.$emit('remote-file-removed', file);
    },
    hasInvalidFiles(payload) {
      this.$emit('has-invalid-files', payload);
    },
    reset() {
      this.$emit('file-removed', [...this.files]);
      this.files = [];
      this.$refs.fileUpload.value = '';
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.file-uploader';
</style>
