<template>
  <span v-if="type === 'text'" class="mc-tag-text" :class="setClasses">
    <span class="mc-tag-text__label">
      {{ label }}
    </span>
  </span>
  <a
    v-else-if="type === 'link'"
    class="mc-tag-link"
    :href="href"
    :class="setClasses"
  >
    <span class="mc-tag-link__label">
      {{ label }}
    </span>
  </a>
  <span
    v-else-if="type === 'selectable'"
    class="mc-tag-selectable"
    :class="setClasses"
  >
    <input
      :id="id"
      class="mc-tag-selectable__input"
      type="checkbox"
      :name="name"
      :value="value"
      :checked="checked"
      :required="required"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
    />
    <label class="mc-tag-selectable__pill" :for="id">
      <span class="mc-tag-selectable__label">
        {{ label }}
      </span>
    </label>
  </span>
  <span
    v-else-if="type === 'removable'"
    class="mc-tag-removable"
    :class="setClasses"
  >
    <span class="mc-tag-removable__label">
      {{ label }}
    </span>
    <button
      type="button"
      class="mc-tag-removable__remove"
      @click="$emit('remove-tag')"
    />
  </span>
</template>

<script>
import {
  responsiveModifierValidators,
  responsiveModifiers,
} from '../../utils/mozaicClasses';

export default {
  name: 'MTag',

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) =>
        ['text', 'link', 'selectable', 'removable'].includes(value),
    },
    theme: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) => responsiveModifierValidators(value, ['s', 'm']),
    },
    // Experimental solution waiting on this issue https://github.com/adeo/mozaic-vue/issues/52
    responsiveSizeModifiers: {
      type: Array,
      default: null,
      validator: (array) =>
        array.every((e) =>
          responsiveModifierValidators(e, [
            's@from-m',
            's@from-l',
            's@from-xl',
            's@from-xxl',
            'm@from-m',
            'm@from-l',
            'm@from-xl',
            'm@from-xxl',
          ])
        ),
    },
  },

  data() {
    return {
      id: null,
    };
  },

  computed: {
    setClasses() {
      const classes = [];

      if (this.size) {
        classes.push(`mc-tag-${this.type}--${this.size}`);
      }

      if (this.theme) {
        classes.push(`mc-tag-${this.type}--${this.theme}`);
      }

      if (
        this.responsiveSizeModifiers &&
        this.responsiveSizeModifiers.length > 0
      ) {
        for (const modifier of this.responsiveSizeModifiers) {
          responsiveModifiers('mc-tag', modifier, classes);
        }
      }

      return classes;
    },
  },

  created() {
    this.id = this._uid;
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.tag';
</style>
