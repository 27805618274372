import MRadio from './MRadio.vue';
import MRadioGroup from './MRadioGroup.vue';

MRadio.install = function (Vue) {
  Vue.component(MRadio.name, MRadio);
};

MRadioGroup.install = function (Vue) {
  Vue.component(MRadioGroup.name, MRadioGroup);
};

export { MRadio, MRadioGroup };
