<template>
  <select
    :id="id"
    ref="select"
    class="mc-select"
    :class="[setClasses, cssFieldElementClass]"
    :value="getSelectValue"
    :disabled="disabled"
    @change="onChange($event.target.value)"
    v-on="inputListeners"
  >
    <option v-if="placeholder" value="" disabled="disabled">
      -- {{ placeholder }} --
    </option>
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="option.value"
      v-bind="option.attributes"
      :disabled="option.disabled"
    >
      <slot name="text" :option="option">
        {{ option.text }}
      </slot>
    </option>
    <slot name="options" />
  </select>
</template>

<script>
export default {
  name: 'MSelect',
  inject: {
    cssFieldElementClass: {
      default: '',
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) => ['s', 'm'].includes(value),
    },
    placeholder: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      optionValue: null,
    };
  },

  computed: {
    getSelectValue() {
      return this.optionValue ? this.optionValue : this.value;
    },

    setClasses() {
      const classes = [
        {
          'is-valid': this.isValid,
          'is-invalid': this.isInvalid,
        },
      ];

      if (this.size) {
        classes.push(`mc-select--${this.size}`);
      }

      return classes;
    },
    inputListeners: function () {
      // see => https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
      var vm = this;
      return Object.assign({}, this.$listeners, {
        change: function (event) {
          vm.$emit('change', event.target.value);
        },
      });
    },
  },

  mounted() {
    this.getOptionValue();
  },

  methods: {
    getOptionValue() {
      if (!this.value) {
        const firstOption = this.$el.querySelector('option');
        this.optionValue = firstOption.value;
      }
    },
    onChange(value) {
      const optionText = this.$refs.select
        .querySelector(`option[value="${value}"]`)
        .textContent.trim();
      const optionData = {
        text: optionText,
        value: value,
      };

      this.$emit('change', value, optionData);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.select';
</style>
