import MStarsInput from './MStarsInput.vue';
import MStarsResult from './MStarsResult.vue';

MStarsInput.install = function (Vue) {
  Vue.component(MStarsInput.name, MStarsInput);
};

MStarsResult.install = function (Vue) {
  Vue.component(MStarsResult.name, MStarsResult);
};

export { MStarsInput, MStarsResult };
