<template>
  <div>
    <div
      ref="layer"
      class="mc-layer"
      :class="{ 'mc-layer--ltr': position === 'left' }"
      role="dialog"
      aria-labelledby="layerTitle"
      :aria-modal="open"
      :aria-hidden="!open"
      tabindex="-1"
      @keyup.esc="closeLayer()"
    >
      <div
        class="mc-layer__dialog"
        :class="{ 'is-open': open, 'mc-layer__dialog--extend': extended }"
        role="document"
      >
        <div class="mc-layer__header">
          <m-icon v-if="headerIcon" :name="headerIcon" class="mc-layer__icon" />
          <component
            :is="layerTitleTag"
            id="layerTitle"
            class="mc-layer__title"
          >
            {{ layerTitle }}
          </component>
          <button
            ref="close"
            class="mc-layer__close"
            type="button"
            @click="closeLayer()"
          >
            <span class="mc-layer__close-text">
              {{ closeButtonText }}
            </span>
          </button>
        </div>
        <div ref="body" class="mc-layer__body">
          <div ref="content" class="mc-layer__content">
            <slot />
          </div>
        </div>
        <div v-if="$slots.footer" class="mc-layer__footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
    <div
      class="mc-layer-overlay"
      :class="{ 'is-visible': open }"
      tabindex="-1"
      role="dialog"
      @click="closeOnOverlay && closeLayer()"
    />
  </div>
</template>

<script>
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MLayer',

  components: {
    MIcon,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    headerIcon: {
      type: String,
      default: null,
    },
    layerTitle: {
      type: String,
      required: true,
    },
    layerTitleTag: {
      type: String,
      default: 'h2',
    },
    closeButtonText: {
      type: String,
      default: 'Close',
      validator: (value) => value !== '',
    },
    extended: {
      type: Boolean,
      default: false,
    },
    closeOnOverlay: {
      type: Boolean,
      default: false,
    },
    scrollbarEdge: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'right',
      validator: (value) => ['right', 'left'].includes(value),
    },
  },

  watch: {
    open(isOpen) {
      this.$nextTick(() => {
        if (isOpen) {
          this.trapFocus();
          document.body.classList.add('mc-layer-open');
          this.$emit('layer-opened');
        } else {
          document.body.classList.remove('mc-layer-open');
          this.$emit('layer-closed');
        }
      });
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.setOverflow();
    });
  },

  methods: {
    closeLayer: function () {
      this.$emit('update:open', false);
    },

    trapFocus: function () {
      const layer = document.querySelector('.mc-layer');
      const focusableElements = layer.querySelectorAll(
        'a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstFocusableElement = focusableElements[0]; // get first element to be focused inside layer
      const lastFocusableElement =
        focusableElements[focusableElements.length - 1];

      document.addEventListener('keydown', function (e) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus(); // add focus for the last focusable element
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus(); // add focus for the first focusable element
            e.preventDefault();
          }
        }
      });
    },

    setOverflow: function () {
      const layer = this.$refs.layer;
      const body = this.$refs.body;
      const content = this.$refs.content;
      const overflowingClass = !this.scrollbarEdge
        ? 'mc-layer__body--overflowing'
        : 'mc-layer__body--overflowing-scroll-edge';

      const ro = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const bodyHeight =
            layer.querySelector('.mc-layer__body').clientHeight;
          const contentHeight = entry.target.scrollHeight;

          if (contentHeight > bodyHeight) {
            body.classList.add(overflowingClass);
          } else {
            body.classList.remove(overflowingClass);
          }
        }
      });

      ro.observe(content);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.layer';

// NOTE: temporary fix
.mc-layer__body--overflowing,
.mc-layer__body--overflowing-scroll-edge {
  &::after {
    pointer-events: none;
  }
}
</style>
