<template>
  <div class="mc-progressbar" :class="setClasses">
    <div
      ref="indicator"
      class="mc-progressbar__indicator"
      role="progressbar"
      :style="`width: ${setProgressValue()}%;`"
      :aria-valuenow="value"
      :aria-valuemin="valueMin"
      :aria-valuemax="valueMax"
    >
      &nbsp;
    </div>
    <div v-if="percent" class="mc-progressbar__percentage">
      {{ setProgressValue() }}%
    </div>
  </div>
</template>

<script>
export default {
  name: 'MProgress',

  props: {
    value: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) => ['xs', 's', 'm'].includes(value),
    },
    valueMin: {
      type: Number,
      default: 0,
    },
    valueMax: {
      type: Number,
      default: 100,
    },
    branded: {
      type: Boolean,
      default: false,
    },
    percent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentValue: this.value,
    };
  },

  computed: {
    setClasses() {
      const classes = [
        {
          'mc-progressbar--branded': this.branded,
          'mc-progressbar--percent': this.percent,
          'mc-progressbar--half':
            this.percent && this.currentValue >= this.valueMax / 2,
        },
      ];

      if (this.size) {
        classes.push(`mc-progressbar--${this.size}`);
      }

      return classes;
    },
  },

  methods: {
    setProgressValue() {
      const initValue = this.value;

      if (initValue > this.valueMax) {
        /* eslint-disable no-console */
        console.error(
          // eslint-disable-next-line quotes
          "You can't provide a value that is greater than the valueMax property"
        );
        /* eslint-enable no-console */
        return this.currentValue;
      }

      this.currentValue = Math.round(initValue);

      return this.currentValue;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.progressbar';
</style>
