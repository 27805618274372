<template>
  <div :class="['mc-tabs', setClasses]">
    <ul
      v-if="type !== 'dropdown'"
      ref="tablist"
      class="mc-tabs__nav"
      role="tablist"
      :aria-label="description"
    >
      <li
        v-for="(tab, index) in tabs"
        :key="`tab-${index}`"
        class="mc-tabs__item"
        role="presentation"
      >
        <component
          :is="`${tab.router}`"
          v-if="tab.router"
          v-bind="tab.attrs"
          :to="tab.to"
          class="mc-tabs__element"
          :class="setLinkClass(tab)"
          active-class="mc-tabs__element--selected"
        >
          <span class="mc-tabs__text">{{ tab.text }}</span>
        </component>
        <component
          :is="tab.href ? (tab.disabled ? 'span' : 'a') : 'button'"
          v-else
          :id="tab.id"
          ref="tab"
          :href="tab.href ? (!tab.disabled ? tab.href : null) : null"
          :type="!tab.href ? 'button' : null"
          :disabled="!tab.href && tab.disabled ? true : null"
          class="mc-tabs__element"
          role="tab"
          :aria-selected="tab.active ? 'true' : 'false'"
          :class="setLinkClass(tab)"
          v-bind="tab.attrs"
          @click="onTabClicked($event, tab, index)"
        >
          <span class="mc-tabs__text">{{ tab.text }}</span>
        </component>
      </li>
    </ul>
    <m-select v-else v-bind="selectAttributes" :options="tabs" />
  </div>
</template>

<script>
import MSelect from '../select/MSelect.vue';

export default {
  name: 'MTab',

  components: {
    MSelect,
  },

  props: {
    tabs: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default: 'tabs',
      validator: (value) => ['tabs', 'dropdown'].includes(value),
    },
    description: {
      type: String,
      default: 'Tabs',
    },
    align: {
      type: String,
      default: 'auto',
      validator: (value) => ['auto', 'full', 'centered'].includes(value),
    },
    shadow: {
      type: Boolean,
      default: true,
    },
    selectAttributes: {
      type: Object,
      default: null,
    },
    activeIndex: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      tablist: null,
      localActiveIndex: null,
    };
  },

  computed: {
    setClasses() {
      return {
        'mc-tabs--full': this.align === 'full',
        'mc-tabs--full-centered': this.align === 'centered',
        'mc-tabs--dropdown': this.type === 'dropdown',
        'mc-tabs--no-divider': !this.shadow,
      };
    },
  },

  watch: {
    activeIndex(newValue) {
      this.localActiveIndex = newValue;
    },
    localActiveIndex: {
      handler: function (newValue) {
        const selectedTab = this.getTabFromIndex(newValue);
        if (selectedTab) {
          this.setTabState(selectedTab);
        }
      },
      immediate: true,
    },
  },

  mounted: function () {
    this.$nextTick(() => {
      if (this.type === 'tabs') {
        this.tablist = this.$refs.tablist;

        if (this.activeIndex) {
          this.localActiveIndex = this.activeIndex;
        } else {
          const isActive = this.tabs.some(
            (tab) =>
              Object.prototype.hasOwnProperty.call(tab, 'active') &&
              tab.active === true
          );

          if (!isActive) {
            this.localActiveIndex = 0;
          }
        }
      }
    });
  },

  methods: {
    setLinkClass: function (tab) {
      return {
        'mc-tabs__element--selected': tab.active,
        'mc-tabs__element--disabled': tab.disabled,
      };
    },
    setTabState(tab) {
      this.tablist.querySelectorAll('.mc-tabs__element').forEach((el) => {
        el.classList.remove('mc-tabs__element--selected');
        el.setAttribute('aria-selected', 'false');
      });

      tab.classList.add('mc-tabs__element--selected');
      tab.setAttribute('aria-selected', 'true');
    },
    getTabFromIndex: function (index) {
      if (
        this.tablist &&
        this.tablist.children[index] &&
        this.tablist.children[index].children[0]
      ) {
        return this.tablist.children[index].children[0];
      }
    },
    onTabClicked(e, tab, index) {
      if (tab && tab.disabled) {
        return;
      }

      if (typeof this.activeIndex !== 'number') {
        this.localActiveIndex = index;
      }

      this.$emit('tab-clicked', e.target, Object.assign({ index: index }, tab));
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.tabs';
</style>
