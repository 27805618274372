<template>
  <div class="mc-card" :class="{ 'mc-card--bordered': outlined }">
    <figure
      class="mc-card__visual"
      :class="imageratio ? `mc-card__visual--${imageratio}` : ''"
    >
      <img :src="imgSrc" :loading="lazy ? 'lazy' : 'eager'" :alt="imgAlt" class="mc-card__img" />
      <slot name="image" />
    </figure>
    <article class="mc-card__content">
      <component :is="titleTag" class="mc-card__title">
        {{ title }}
      </component>
      <component :is="subtitleTag" v-if="subtitle" class="mc-card__subtitle">
        {{ subtitle }}
      </component>
      <div class="mc-card__body">
        <slot />
      </div>
      <footer v-if="$slots.footer" class="mc-card__footer">
        <slot name="footer" />
      </footer>
    </article>
  </div>
</template>

<script>
export default {
  name: 'MCard',

  props: {
    title: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    titleTag: {
      type: String,
      default: 'h1',
      validator: (value) =>
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value),
    },
    imgAlt: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: null,
    },
    subtitleTag: {
      type: String,
      default: 'h2',
    },
    imageratio: {
      type: String,
      default: null,
      validator: (value) => ['1x1', '4x3', '16x9'].includes(value),
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.card';
</style>
