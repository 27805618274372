<template>
  <div class="mc-option-card" :class="setClasses">
    <input
      :id="id"
      :type="setType"
      class="mc-option-card__input"
      :class="setInputClasses"
      :name="name"
      :required="required"
      :checked="checked"
      v-bind="inputAttrs"
      @change="$emit('change', $event.target.checked)"
    />
    <label :for="id" class="mc-option-card__label">
      <span class="mc-option-card__label-text">{{ label }}</span>
    </label>
    <div class="mc-option-card__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MOptionCard',

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'radio',
      validator: (value) => ['radio', 'checkbox'].includes(value),
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 1,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    inputAttrs: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    setType() {
      return this.type === 'checkbox' ? 'checkbox' : 'radio';
    },
    setClasses() {
      return {
        'mc-option-card--centered': this.centered,
        'mc-option-card--no-padding': this.noPadding,
        'mc-option-card--small': this.small,
      };
    },
    setInputClasses() {
      return {
        'mc-checkbox__input': this.type === 'checkbox',
        'mc-radio__input': this.type === 'radio',
      };
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.option-card';

/* stylelint-disable */
// temporary fix
.mc-option-card {
  $parent: get-parent-selector(&);

  border-radius: get-border-radius('m');
  cursor: pointer;
  position: relative;

  &__input {
    @at-root input#{&} {
      &:checked ~ #{$parent}__content {
        button,
        [href],
        input,
        select,
        textarea,
        label,
        .mc-option-card__focusable,
        [tabindex]:not([tabindex='-1']) {
          position: relative;
        }
      }
    }
  }
}
/* stylelint-enable */
</style>
