<template>
  <div
    v-click-outside="onClickOutside"
    class="mc-autocomplete"
    :class="{ 'mc-autocomplete--multi': multiple }"
    :style="setStyles"
  >
    <MTag
      v-if="multiple && listboxValue.length > 0"
      :id="tagId ? tagId : `autocompleteTag-${uuid}`"
      ref="tag"
      :label="setTagLabel"
      :disabled="disabled"
      type="removable"
      class="mc-autocomplete__tag"
      size="s"
      @remove-tag="clearAutocomplete()"
    />
    <MLoader v-if="loading" class="mc-autocomplete__loader" size="s" />
    <MTextInput
      :id="id"
      v-model="inputValue"
      :placeholder="placeholder"
      :is-invalid="invalid"
      :disabled="disabled"
      text-input-field-class="mc-autocomplete__trigger"
      icon-position="left"
      icon="DisplaySearch48"
      autocomplete="off"
      type="search"
      @input="onInput"
      @click="openState = true"
    />
    <MListBox
      v-model="listboxValue"
      :open="openState"
      :items="localItems"
      :multiple="multiple"
      :empty-search-label="emptySearchLabel"
      :data-key-expr="dataKeyExpr"
      :data-text-expr="dataTextExpr"
      :data-value-expr="dataValueExpr"
      :is-filtered="isFiltered"
      :max-width="maxWidth"
      @change="onChange"
    >
      <template #item="{ item }">
        <slot name="item" :item="item"></slot>
      </template>
    </MListBox>
  </div>
</template>

<script>
import MTag from '../tags/MTag.vue';
import MLoader from '../loader/MLoader.vue';
import MTextInput from '../textinput/MTextInput.vue';
import MListBox from '../listbox/MListBox.vue';

export default {
  name: 'MAutocomplete',

  components: {
    MTag,
    MLoader,
    MTextInput,
    MListBox,
  },

  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },

  model: {
    event: 'change',
  },

  props: {
    // Tag Element
    tagId: {
      type: String,
      default: null,
    },
    tagLabel: {
      type: String,
      default: '',
    },
    // Input Element
    id: {
      type: String,
      default: null,
    },
    input: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    filter: {
      type: Function,
      default: null,
    },
    filterOnType: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // Listbox Element
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: [Array, String, Number],
      default: undefined,
    },
    open: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    emptySearchLabel: {
      type: String,
      default: 'No results found',
    },
    dataKeyExpr: {
      type: String,
      default: 'id',
    },
    dataTextExpr: {
      type: String,
      default: 'label',
    },
    dataValueExpr: {
      type: String,
      default: 'value',
    },
    sort: {
      type: Boolean,
      default: true,
    },
    // Global
    maxWidth: {
      type: String,
      default: '100%', // 17.875rem
    },
  },

  data() {
    return {
      uuid: Math.random(),
      openState: this.open,
      tagWidth: '0px',
      tagValue: null,
      inputValue: null,
      localItems: null,
      sortedListItems: null,
      listboxValue: null,
      isFiltered: null,
    };
  },

  computed: {
    setTagLabel() {
      return this.listboxValue.length.toString() + ' ' + this.tagLabel;
    },
    setStyles() {
      return {
        '--tag-width': this.tagWidth,
        '--max-width': this.maxWidth,
      };
    },
  },

  watch: {
    value: {
      handler: function (val) {
        if (!val && this.multiple) {
          this.listboxValue = [];
        } else {
          this.listboxValue = val;
        }
      },
      immediate: true,
    },

    items: {
      handler: function (val) {
        this.localItems = val;
        // this.clearAutocomplete();
      },
      immediate: true,
    },

    listboxValue: function (val) {
      const selectedItems = this.getSelectedItems(val);

      const seletedLabels = selectedItems.map(
        (item) => item[this.dataTextExpr]
      );

      this.inputValue = seletedLabels.join(', ');

      if (this.multiple) {
        this.tagValue = val;
      }
    },

    tagValue: function () {
      this.setTagWidth();
    },

    openState: function (val) {
      const eventName = val ? 'open' : 'close';
      this.$emit(eventName);
      this.$emit('update:open', val);
    },

    input: {
      handler: function (val) {
        this.inputValue = val;
      },
      immediate: true,
    },
  },

  methods: {
    setTagWidth() {
      this.$nextTick(() => {
        if (this.$refs.tag && this.$refs.tag.$el) {
          this.tagWidth = this.$refs.tag.$el.clientWidth + 'px';
        } else {
          this.tagWidth = '0px';
        }
      });
    },

    clearAutocomplete() {
      this.listboxValue = this.multiple ? [] : undefined;
      this.onChange();
      this.$emit('clear');
    },

    filterList(value) {
      if (value.length && this.filter) {
        this.filter(value);
      } else if (value.length && this.filterOnType) {
        this.localItems = this.items.filter((item) =>
          item[this.dataTextExpr].toUpperCase().includes(value.toUpperCase())
        );

        this.isFiltered = !this.localItems.length;
      } else {
        this.localItems = this.items;
        this.isFiltered = !this.localItems.length;
      }
      this.$emit('list-filtered', this.localItems);
    },

    onClickOutside() {
      this.openState = false;

      if (this.multiple && this.sort) {
        this.sortItems();
      } else {
        this.localItems = this.items;
      }
    },

    onChange() {
      this.$emit('change', this.listboxValue);

      if (!this.multiple) {
        this.onClickOutside();
      }
    },

    getSelectedItems(val) {
      const value = val ? val : this.listboxValue;

      const selectedItems = this.items.filter((item) => {
        return this.multiple
          ? value.includes(item[this.dataValueExpr])
          : value === item[this.dataValueExpr];
      });

      return selectedItems;
    },

    sortItems() {
      this.sortedListItems = this.items;
      const selectedItems = this.getSelectedItems();

      this.sortedListItems.sort((a, b) => {
        const hasItemA = selectedItems.includes(a);
        const hasItemB = selectedItems.includes(b);

        if (hasItemA === hasItemB) {
          return a[this.dataValueExpr] - b[this.dataValueExpr];
        } else if (hasItemA < hasItemB) {
          return 1;
        } else {
          return -1;
        }
      });

      this.localItems = this.sortedListItems;
    },

    handleInputValue(value) {
      this.$emit('update:input', value);
    },

    onInput(value) {
      this.handleInputValue(value);
      this.filterList(value);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.autocomplete';

.mc-autocomplete {
  max-width: var(--max-width);

  &__tag {
    position: absolute;
    top: 0;
    transform: translateY(50%);
  }

  &__loader {
    position: absolute;
    right: $mu075;
    top: $mu075;
    z-index: 10;
  }

  &__trigger {
    width: 100%;
  }
}

.mc-autocomplete--multi .mc-autocomplete__trigger {
  overflow: hidden;
  padding-left: calc(2.9375rem + var(--tag-width));
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
