<template>
  <a v-if="href" :href="href" class="mc-button" :class="setClasses" :aria-label="ariaLabel">
    <m-icon
      v-if="icon && iconPosition === 'left'"
      :id="`mc-button__${iconPosition}-icon-${id}`"
      :class="`mc-button__icon mc-button__icon--${iconPosition}`"
      :name="icon"
    />
    <span v-if="label" class="mc-button__label">
      {{ label }}
    </span>
    <m-icon
      v-if="icon && iconPosition === 'right'"
      :id="`mc-button__${iconPosition}-icon-${id}`"
      :class="`mc-button__icon mc-button__icon--${iconPosition}`"
      :name="icon"
    />
  </a>
  <button
    v-else
    class="mc-button"
    :disabled="disabled"
    :class="setClasses"
    :aria-label="ariaLabel"
    @click="$emit('click', $event)"
  >
    <m-icon
      v-if="icon && iconPosition === 'left'"
      :id="`mc-button__${iconPosition}-icon-${id}`"
      :class="`mc-button__icon mc-button__icon--${iconPosition}`"
      :name="icon"
    />
    <span v-if="label" class="mc-button__label">
      {{ label }}
    </span>
    <m-icon
      v-if="icon && iconPosition === 'right'"
      :id="`mc-button__${iconPosition}-icon-${id}`"
      :class="`mc-button__icon mc-button__icon--${iconPosition}`"
      :name="icon"
    />
  </button>
</template>

<script>
import {
  responsiveModifiers,
  responsiveModifierValidators,
} from '../../utils/mozaicClasses';
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MButton',

  components: {
    MIcon,
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) =>
        responsiveModifierValidators(value, [
          's',
          's@from-m',
          's@from-l',
          's@from-xl',
          's@from-xxl',
          'm',
          'm@from-m',
          'm@from-l',
          'm@from-xl',
          'm@from-xxl',
          'l',
          'l@from-m',
          'l@from-l',
          'l@from-xl',
          'l@from-xxl',
        ]),
    },
    width: {
      type: String,
      default: null,
      validator: (value) =>
        responsiveModifierValidators(value, [
          'fit',
          'fit@from-m',
          'fit@from-l',
          'fit@from-xl',
          'fit@from-xxl',
          'full',
          'full@from-m',
          'full@from-l',
          'full@from-xl',
          'full@from-xxl',
        ]),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value),
    },
    ariaLabel: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      id: null,
    };
  },

  computed: {
    setClasses() {
      const classes = [];

      if (this.theme) {
        classes.push(`mc-button--${this.theme}`);
      }

      if (this.size) {
        responsiveModifiers('mc-button', this.size, classes);
      }

      if (this.width) {
        responsiveModifiers('mc-button', this.width, classes);
      }

      if (!this.label) {
        classes.push('mc-button--square');
      }

      return classes;
    },
  },

  created() {
    this.id = this._uid;
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/c.button';
</style>
