var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"mc-breadcrumb",class:{
    'mc-breadcrumb--dark': _vm.dark,
    'mc-breadcrumb--responsive': _vm.responsive,
  },attrs:{"aria-label":"Breadcrumb"}},[_c('ul',{staticClass:"mc-breadcrumb__container"},_vm._l((_vm.items),function(item,index){return _c('li',{key:`breadcrumb-${index}`,staticClass:"mc-breadcrumb__item",class:{ 'is-active': item.isActive }},[_c('m-link',{class:[
          _vm.setLinkClasses,
          { 'mc-breadcrumb__current': index === _vm.items.length - 1 },
        ],attrs:{"href":item.href,"aria-current":index != _vm.items.length - 1 ? null : 'page',"size":"s","router":item.router},on:{"click":function($event){return _vm.$emit('link-clicked', item)}}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }