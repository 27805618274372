<template>
  <div
    ref="dropdown"
    v-click-outside="onClickOutside"
    class="mc-dropdown"
    :class="{ 'mc-dropdown--multi': multiple }"
    :style="setStyles"
  >
    <MTag
      v-if="multiple && listboxValue.length > 0"
      :id="tagId ? tagId : `autoCompleteTag-${uuid}`"
      ref="tag"
      :label="setTagLabel"
      :disabled="disabled"
      type="removable"
      class="mc-dropdown__tag"
      size="s"
      @remove-tag="clearAutocomplete()"
    />
    <button
      type="button"
      class="mc-select mc-dropdown__trigger"
      :class="{ 'is-open': openState }"
      :disabled="disabled"
      @click="openState = !openState"
    >
      {{ buttonValue }}
    </button>
    <MListBox
      v-model="listboxValue"
      :open="openState"
      :items="localItems"
      :multiple="multiple"
      :empty-search-label="emptySearchLabel"
      :data-key-expr="dataKeyExpr"
      :data-text-expr="dataTextExpr"
      :data-value-expr="dataValueExpr"
      @change="onChange"
    >
      <template #item="{ item }">
        <slot name="item" :item="item"></slot>
      </template>
    </MListBox>
  </div>
</template>

<script>
import MTag from '../tags/MTag.vue';
import MListBox from '../listbox/MListBox.vue';

export default {
  name: 'MDropdown',

  components: {
    MTag,
    MListBox,
  },

  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },

  model: {
    event: 'change',
  },

  props: {
    // Tag Element
    tagId: {
      type: String,
      default: null,
    },
    tagLabel: {
      type: String,
      default: '',
    },
    // Input Element
    placeholder: {
      type: String,
      default: '-- Placeholder --',
    },
    filter: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // Listbox Element
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: [Array, String, Number],
      default: undefined,
    },
    open: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    emptySearchLabel: {
      type: String,
      default: 'No results found',
    },
    dataKeyExpr: {
      type: String,
      default: 'id',
    },
    dataTextExpr: {
      type: String,
      default: 'label',
    },
    dataValueExpr: {
      type: String,
      default: 'value',
    },
    sort: {
      type: Boolean,
      default: true,
    },
    // Global
    maxWidth: {
      type: String,
      default: '17.875rem',
    },
  },

  data() {
    return {
      uuid: Math.random(),
      openState: this.open,
      tagWidth: '0px',
      tagValue: null,
      buttonValue: this.placeholder,
      localItems: null,
      sortedListItems: null,
      listboxValue: null,
    };
  },

  computed: {
    setTagLabel() {
      return this.listboxValue.length.toString() + ' ' + this.tagLabel;
    },
    setStyles() {
      return {
        '--tag-width': this.tagWidth,
        '--max-width': this.maxWidth,
      };
    },
  },

  watch: {
    value: {
      handler: function (val) {
        if (!val && this.multiple) {
          this.listboxValue = [];
        } else {
          this.listboxValue = val;
        }
      },
      immediate: true,
    },

    items: {
      handler: function (val) {
        this.localItems = val;
        // this.clearAutocomplete();
      },
      immediate: true,
    },

    listboxValue: function (val) {
      const value = Array.isArray(val) ? val : val.toString();
      const selectedItems = this.getSelectedItems(value);

      const seletedLabels = selectedItems.map(
        (item) => item[this.dataTextExpr]
      );

      this.buttonValue = seletedLabels.join(', ');

      if (val.length === 0) {
        this.buttonValue = this.placeholder;
      }

      if (this.multiple) {
        this.tagValue = val;
      }
    },

    tagValue: function () {
      this.setTagWidth();
    },

    openState: function (val) {
      const eventName = val ? 'open' : 'close';
      this.$emit(eventName);
      this.$emit('update:open', val);
    },
  },

  methods: {
    setTagWidth() {
      this.$nextTick(() => {
        if (this.$refs.tag && this.$refs.tag.$el) {
          this.tagWidth = this.$refs.tag.$el.clientWidth + 8 + 'px';
        } else {
          this.tagWidth = '0px';
        }
      });
    },

    clearAutocomplete() {
      this.listboxValue = this.multiple ? [] : undefined;
      this.onChange();
      this.$emit('clear');
    },

    onClickOutside() {
      this.openState = false;

      if (this.multiple && this.sort) {
        this.sortItems();
      } else {
        this.localItems = this.items;
      }
    },

    onChange() {
      this.$emit('change', this.listboxValue);

      if (!this.multiple) {
        this.onClickOutside();
      }
    },

    getSelectedItems(val) {
      const value = val ? val : this.listboxValue;

      const selectedItems = this.items.filter((item) =>
        value.includes(item[this.dataValueExpr])
      );

      return selectedItems;
    },

    sortItems() {
      this.sortedListItems = this.items;
      const selectedItems = this.getSelectedItems();

      this.sortedListItems.sort((a, b) => {
        const hasItemA = selectedItems.includes(a);
        const hasItemB = selectedItems.includes(b);

        if (hasItemA === hasItemB) {
          return a[this.dataValueExpr] - b[this.dataValueExpr];
        } else if (hasItemA < hasItemB) {
          return 1;
        } else {
          return -1;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.checkbox';
@import 'components/_c.dropdown';

.mc-dropdown {
  max-width: var(--max-width);

  &__tag {
    position: absolute;
    top: 0;
    transform: translateY(50%);
  }

  &__trigger {
    display: block;
    text-align: left;

    &.is-open {
      background-image: url(inline-icons('arrow-top-16', black));
    }
  }
}

.mc-dropdown--multi .mc-dropdown__trigger {
  overflow: hidden;
  padding-left: calc(0.75rem + var(--tag-width));
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
