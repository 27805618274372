<template>
  <ul
    role="listbox"
    class="mc-listbox"
    aria-labelledby="listbox"
    :class="{ 'is-open': open, 'mc-listbox--multi': multiple }"
    :style="{ '--listbox-width': maxWidth }"
  >
    <template v-if="!isFiltered">
      <li
        v-for="(item, index) in localItems"
        :key="item.id"
        class="mc-listbox__item"
      >
        <MIcon
          v-if="item.icon"
          :name="item.icon"
          class="mc-listbox__icon"
          color="#666666"
        />
        <input
          :id="setItemId(index)"
          v-model="localValue"
          class="mc-listbox__input"
          :class="{ 'mc-checkbox__input': multiple }"
          :type="multiple ? 'checkbox' : 'radio'"
          :value="item[dataValueExpr]"
          :name="!multiple ? `listboxradio-${uuid}` : null"
          @change="onChange"
        />
        <label :for="setItemId(index)" class="mc-listbox__label">
          <slot name="item" :item="item">
            {{ item[dataTextExpr] }}
          </slot>
        </label>
      </li>
    </template>
    <li v-else class="mc-listbox__item">
      <span class="mc-listbox__empty">{{ emptySearchLabel }}</span>
    </li>
  </ul>
</template>

<script>
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MListBox',

  components: {
    MIcon,
  },

  model: {
    event: 'change',
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    isFiltered: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    emptySearchLabel: {
      type: String,
      default: 'No item matching your criteria found',
    },
    dataKeyExpr: {
      type: String,
      default: 'id',
    },
    dataTextExpr: {
      type: String,
      default: 'label',
    },
    dataValueExpr: {
      type: String,
      default: 'value',
    },
    value: {
      type: [Array, String, Number],
      default: undefined,
    },
    maxWidth: {
      type: String,
      default: '17.875rem',
    },
  },

  data() {
    return {
      uuid: Math.random(),
      localItems: undefined,
      localValue: undefined,
    };
  },

  computed: {
    setItemId() {
      return (index) => `listboxItem-${index + this.uuid}`;
    },
  },

  watch: {
    items: {
      handler: function (val) {
        this.localItems = val;
      },
      immediate: true,
    },
    value: {
      handler: function (val) {
        this.localValue = val;
      },
      immediate: true,
    },
  },

  methods: {
    onChange() {
      this.$emit('change', this.localValue);
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.checkbox';
@import 'components/c.listbox';

.mc-listbox__empty {
  margin-top: 0;
}
</style>
