<template>
  <div class="mc-pagination" :class="{ 'mc-pagination--light': light }">
    <component
      :is="hrefPrev ? 'a' : 'button'"
      v-if="value > 1"
      :type="hrefPrev ? null : 'button'"
      :href="hrefPrev"
      class="mc-pagination__button"
      aria-label="Previous Page"
      :disabled="disabled"
      @click="previousPage(currentPage - 1)"
    >
      <m-icon name="ArrowArrowLeft24" class="mc-pagination__button-icon" />
    </component>

    <div v-if="!light" class="mc-pagination__field">
      <m-select
        :id="`selectpagination${selectId}`"
        v-model="currentPage"
        :options="formattedOptions"
        :disabled="disabled"
        class="mc-pagination__select"
        @change="changePage(Number($event))"
      >
        <template #text="{ option }">
          <slot name="text" :option="{ ...option, length }">
            {{ option.text }}
          </slot>
        </template>
      </m-select>
    </div>

    <component
      :is="hrefNext ? 'a' : 'button'"
      v-if="value < length"
      :type="hrefNext ? null : 'button'"
      :href="hrefNext"
      class="mc-pagination__button"
      aria-label="Next Page"
      :disabled="disabled"
      @click="nextPage(currentPage + 1)"
    >
      <m-icon name="ArrowArrowRight24" class="mc-pagination__button-icon" />
    </component>
  </div>
</template>

<script>
import MIcon from '../icon/MIcon.vue';
import MSelect from '../select/MSelect.vue';

export default {
  name: 'MPagination',

  components: {
    MIcon,
    MSelect,
  },

  props: {
    length: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
    pageLabel: {
      type: String,
      default: 'sur',
    },
    light: {
      type: Boolean,
      default: false,
    },
    hrefPrev: {
      type: String,
      default: null,
    },
    hrefNext: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      selectId: null,
      currentPage: Number(this.value),
    };
  },

  computed: {
    formattedOptions() {
      const pageOptions = [];

      for (let i = 1; i <= this.length; i++) {
        pageOptions.push({
          text: `${i} ${this.pageLabel} ${this.length}`,
          value: i,
          index: i,
        });
      }

      if (this.currentPage > this.length) {
        this.changePage(this.length);
      }

      return pageOptions;
    },
  },

  watch: {
    value: {
      handler(newPage) {
        this.currentPage = newPage;
      },
    },
  },

  created() {
    this.selectId = this._uid;
  },

  methods: {
    hasPreviousPageListener() {
      return this.$listeners && this.$listeners['on-previous-page'];
    },
    hasNextPageListener() {
      return this.$listeners && this.$listeners['on-next-page'];
    },
    changePage(newPage) {
      this.$emit('on-update-page', newPage);
      this.$emit('update:value',newPage);
    },
    previousPage(newPage) {
      if (this.hasPreviousPageListener()) {
        this.$emit('on-previous-page', newPage);
      } else {
        this.changePage(newPage);
      }
    },
    nextPage(newPage) {
      if (this.hasNextPageListener()) {
        this.$emit('on-next-page', newPage);
      } else {
        this.changePage(newPage);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'settings-tools/_all-settings';
@import 'components/_c.pagination';
</style>
