<template>
  <div
    class="mc-notification"
    :class="setClasses"
    :role="alert ? 'alert' : null"
  >
    <div class="mc-notification__content">
      <component :is="titleTag" v-if="title" class="mc-notification__title">
        {{ title }}
      </component>
      <div class="mc-notification__message">
        <slot />
      </div>
      <m-link
        v-if="link && linkContent"
        class="mc-notification__link"
        :href="link"
      >
        {{ linkContent }}
      </m-link>
      <div v-else-if="$slots.footer" class="mc-notification__footer">
        <slot name="footer" />
      </div>
    </div>
    <button
      v-if="closable"
      type="button"
      class="mc-notification-closable__close"
      title="Close"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
import MLink from '../link/MLink.vue';

export default {
  name: 'MNotification',

  components: {
    MLink,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    titleTag: {
      type: String,
      default: 'h3',
      validator: (value) =>
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value),
    },
    alert: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
    linkContent: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
      validator: (value) =>
        ['information', 'success', 'warning', 'danger'].includes(value),
    },
    closable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
      validator: (value) => ['s', 'm'].includes(value),
    },
  },

  computed: {
    setClasses() {
      const classes = [];

      if (this.type) {
        classes.push(`mc-notification--${this.type}`);
      }

      if (this.size && this.size !== 'm') {
        classes.push(`mc-notification--${this.size}`);
      }

      if (this.closable) {
        classes.push('mc-notification-closable');
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.notification';
</style>
