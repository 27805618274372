import MDataTable from './MDataTable.vue';
import MDataTableHeader from './MDataTableHeader.vue';

MDataTable.install = function (Vue) {
  Vue.component(MDataTable.name, MDataTable);
};

MDataTableHeader.install = function (Vue) {
  Vue.component(MDataTableHeader.name, MDataTableHeader);
};

export { MDataTable, MDataTableHeader };
