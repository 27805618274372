<template>
  <fieldset class="mc-field mc-field--group">
    <legend class="mc-field__legend">
      {{ legend }}
      <span
        v-if="requirementText"
        class="mc-field__requirement"
        aria-hidden="true"
      >
        {{ requirementText }}
      </span>
    </legend>
    <span v-if="helpId && helpText" :id="helpId" class="mc-field__help">
      {{ helpText }}
    </span>
    <div
      class="mc-field__container"
      :class="{ 'mc-field__container--inline': inline }"
    >
      <m-radio
        v-for="option in options"
        :id="option.id ? option.id : option.value"
        :key="option.id ? option.id : option.value"
        :is-invalid="isInvalid"
        :label="option.label"
        :name="option.name"
        root-class="mc-field__item"
        :checked="value === option.value"
        @change="(v) => (v ? $emit('input', option.value) : null)"
      />
    </div>
    <span v-if="isInvalid" :id="errorId" class="mc-field__error-message">
      {{ errorMessage }}
    </span>
  </fieldset>
</template>

<script>
import MRadio from './MRadio.vue';

export default {
  name: 'MRadioGroup',

  components: {
    MRadio,
  },

  props: {
    legend: {
      type: String,
      required: true,
    },
    requirementText: {
      type: String,
      default: null,
    },
    helpId: {
      type: String,
      default: null,
    },
    helpText: {
      type: String,
      default: null,
    },
    errorId: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },

    value: {
      type: String,
      default: null,
    },

    options: {
      type: Array,
      default() {
        return [];
      },
      validator(options) {
        return options.every(
          (o) =>
            'id' in o &&
            typeof o.id == 'string' &&
            'label' in o &&
            typeof o.label == 'string' &&
            'value' in o &&
            typeof o.value == 'string'
        );
      },
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.fields';
</style>
