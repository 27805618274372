<template>
  <component :is="htmlTag" class="mc-badge" :class="setClasses">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'MBadge',

  props: {
    type: {
      type: String,
      default: null,
      validator: (value) =>
        ['information', 'success', 'warning', 'danger', 'neutral'].includes(
          value
        ),
    },
    htmlTag: {
      type: String,
      default: 'span',
    },
  },

  computed: {
    setClasses() {
      const classes = [];

      if (this.type) {
        classes.push(`mc-badge--${this.type}`);
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.badge';
</style>
