<template>
  <div
    class="mc-option-button"
    :class="{ 'mc-option-button--full': fullWidth }"
  >
    <input
      :id="id"
      type="radio"
      class="mc-option-button__input"
      :name="name"
      :checked="checked"
      :disabled="disabled"
      v-bind="inputAttrs"
      @change="$emit('change', $event.target.checked)"
    />
    <label :for="id" class="mc-option-button__label">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'MOptionButton',

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    inputAttrs: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/c.option-button';
</style>
