<template>
  <div :class="['mc-stars-result', setClasses]">
    <span class="mc-stars-result__visual" />
    <span class="mc-stars-result__text">
      {{ scoreLabel }}: {{ closestScore }}/{{ ratingValue }}
    </span>
  </div>
</template>

<script>
import {
  responsiveModifiers,
  responsiveModifierValidators,
} from '../../utils/mozaicClasses';

const RATINGVALUE = 5;

export default {
  name: 'MStarsResult',

  props: {
    score: {
      type: Number,
      default: 0,
      validator: (value) => value > 0 && value <= 5,
    },
    scoreLabel: {
      type: String,
      default: 'Global score',
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) =>
        responsiveModifierValidators(value, ['s', 'm', 'l', 'xl']),
    },
  },

  data() {
    return {
      id: null,
      ratingValue: RATINGVALUE,
    };
  },
  computed: {
    authorizedValues() {
      const authorizedValue = [];
      for (let i = 0; i <= RATINGVALUE; i += 0.5) {
        authorizedValue.push(i);
      }
      return authorizedValue;
    },
    closestScore() {
      if (this.score > 0 && this.score <= this.ratingValue) {
        return this.authorizedValues.reduce((a, b) => {
          return Math.abs(b - this.score) < Math.abs(a - this.score) ? b : a;
        });
      } else {
        return 0;
      }
    },
    setClasses() {
      const classes = [];

      if (this.size) {
        responsiveModifiers('mc-stars-result', this.size, classes);
      }

      if (this.closestScore) {
        const classNote = `score-${
          (this.closestScore * 100) / this.ratingValue
        }`;
        responsiveModifiers('mc-stars-result', classNote, classes);
      }

      return classes;
    },
  },

  created() {
    this.id = this._uid;
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.stars-result';
</style>
