<template>
  <nav
    class="mc-breadcrumb"
    :class="{
      'mc-breadcrumb--dark': dark,
      'mc-breadcrumb--responsive': responsive,
    }"
    aria-label="Breadcrumb"
  >
    <ul class="mc-breadcrumb__container">
      <li
        v-for="(item, index) in items"
        :key="`breadcrumb-${index}`"
        class="mc-breadcrumb__item"
        :class="{ 'is-active': item.isActive }"
      >
        <m-link
          :href="item.href"
          :class="[
            setLinkClasses,
            { 'mc-breadcrumb__current': index === items.length - 1 },
          ]"
          :aria-current="index != items.length - 1 ? null : 'page'"
          size="s"
          :router="item.router"
          @click="$emit('link-clicked', item)"
        >
          {{ item.text }}
        </m-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import MLink from '../link/MLink.vue';

export default {
  name: 'MBreadcrumb',

  components: {
    MLink,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    setLinkClasses() {
      return {
        'mc-link--light': this.dark,
      };
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.breadcrumb';
</style>
