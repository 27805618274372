<template>
  <nav
    class="mc-stepper"
    :class="{
      'mc-stepper--compact': compact,
      'mc-stepper--shrinked': steps.length > 3,
    }"
    :aria-label="accessibilityLabels.stepperDescription"
  >
    <ol class="mc-stepper__list">
      <li
        v-for="(step, idx) in steps"
        :key="`mc-stepper__item-${idx}`"
        class="mc-stepper__item"
        :class="{
          'mc-stepper__item--validated': isStepValidated(idx),
          'mc-stepper__item--current': step.isCurrent,
        }"
        :aria-current="step.isCurrent ? 'step' : false"
        :aria-label="stepDescription(step, idx)"
        :style="`--steps: ${steps.length}; --current: ${idx + 1};`"
        @click="isStepValidated(idx) && $emit('step-changed', step)"
      >
        <a v-if="step.href && isStepValidated(idx)" :href="step.href" class="mc-stepper__link">
          <div class="mc-stepper__indicator" aria-hidden="true">
            <m-icon
              v-if="isStepValidated(idx)"
              name="NotificationAvailable16"
              class="mc-stepper__icon"
            />
            <span v-else-if="step.isCurrent">{{ idx + 1 }}</span>
          </div>
          <div class="mc-stepper__detail">
            <span class="mc-stepper__title"
              >{{ idx + 1 }} / {{ steps.length }}</span
            >
            <span class="mc-stepper__label">{{ step.label }}</span>
          </div>
        </a>
        <template v-else>
          <div class="mc-stepper__indicator" aria-hidden="true">
            <m-icon
              v-if="isStepValidated(idx)"
              name="NotificationAvailable16"
              class="mc-stepper__icon"
            />
            <span v-else-if="step.isCurrent">{{ idx + 1 }}</span>
          </div>
          <div class="mc-stepper__detail">
            <span class="mc-stepper__title"
              >{{ idx + 1 }} / {{ steps.length }}</span
            >
            <span class="mc-stepper__label">{{ step.label }}</span>
          </div>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MStepper',
  components: {
    MIcon,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    accessibilityLabels: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isStepValidated(index) {
      return index < this.steps.findIndex((step) => step.isCurrent);
    },
    stepDescription(step, index) {
      return (
        '#' +
        (index + 1) +
        ' ' +
        step.label +
        ', ' +
        this.stepStateLabel(step, index)
      );
    },
    stepStateLabel(step, index) {
      return this.isStepValidated(index)
        ? this.accessibilityLabels.validatedLabel
        : step.isCurrent
        ? this.accessibilityLabels.currentLabel
        : this.accessibilityLabels.disabledLabel;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'settings-tools/_all-settings';
@import 'components/_c.stepper';
</style>
