<template>
  <div class="mc-quantity-selector" :class="cssFieldElementClass">
    <m-button
      class="mc-quantity-selector__button-left"
      theme="bordered"
      icon="ControlLess32"
      icon-position="left"
      :aria-label="decrementAriaLabel"
      :aria-controls="id"
      :disabled="disabled ? disabled : currentValue === valuemin"
      :size="small ? 's' : null"
      tabindex="-1"
      type="button"
      @click="decrement()"
    />

    <MTextInputField
      :id="id"
      ref="input"
      :value="currentValue"
      type="number"
      class="mc-quantity-selector__input"
      name="quantity-selector-input"
      :aria-label="inputAriaLabel"
      :aria-valuenow="currentValue"
      :aria-valuemin="valuemin"
      :aria-valuemax="valuemax"
      :placeholder="placeholder"
      :size="small ? 's' : null"
      :disabled="disabled"
      role="spinbutton"
      @input="handle"
      @focus="onFocus"
      @keypress="integerOnly && formatValue($event)"
      v-on="$listeners"
    />

    <m-button
      class="mc-quantity-selector__button-right"
      theme="bordered"
      icon="ControlMore32"
      icon-position="right"
      :aria-label="incrementAriaLabel"
      :aria-controls="id"
      :disabled="disabled ? disabled : currentValue === valuemax"
      :size="small ? 's' : null"
      tabindex="-1"
      type="button"
      @click="increment()"
    />
  </div>
</template>

<script>
import MButton from '../button/MButton.vue';
import MTextInputField from '../textinput/MTextInputField.vue';

export default {
  name: 'MQuantitySelector',

  components: {
    MButton,
    MTextInputField,
  },
  inject: {
    cssFieldElementClass: {
      default: '',
    },
  },
  props: {
    id: {
      type: String,
      default: 'qty-selector',
    },
    value: {
      type: Number,
      default: 0,
    },
    inputAriaLabel: {
      type: String,
      default: 'Quantity Selector',
    },
    decrementAriaLabel: {
      type: String,
      default: 'Decrement',
    },
    incrementAriaLabel: {
      type: String,
      default: 'Increment',
    },
    valuemin: {
      type: Number,
      default: 1,
    },
    valuemax: {
      type: Number,
      default: 100,
    },
    placeholder: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    integerOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentValue: this.value || this.valuemin,
    };
  },
  watch: {
    value() {
      this.currentValue = this.value || this.valuemin;
    },
  },
  methods: {
    handle(value) {
      this.currentValue = value;
      if (this.currentValue > this.valuemax) {
        this.currentValue = this.valuemax;
      }
      if (this.currentValue < this.valuemin) {
        this.currentValue = this.valuemin;
      }
      this.$emit('input', this.currentValue);
    },
    increment() {
      if (this.currentValue < this.valuemax) {
        this.currentValue++;
        this.$emit('increment', this.currentValue);
      }
    },
    decrement() {
      if (this.currentValue > this.valuemin) {
        this.currentValue--;
        this.$emit('decrement', this.currentValue);
      }
    },
    formatValue(e) {
      const INTEGER_ONLY_REGEX = /[0-9/]+/;
      if (!INTEGER_ONLY_REGEX.test(e.key)) {
        e.preventDefault();
      }
    },
    onFocus(e) {
      e.target.select();
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.quantity-selector';
</style>
