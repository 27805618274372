// Export the components as a plugin
import * as Components from './components';

const MozaicVue = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options = {}) {
    // components
    Object.keys(Components).forEach((name) => {
      Vue.component(name, Components[name]);
    });
  },
};

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(MozaicVue);
}

export default MozaicVue;

// import/export individual components
// Foundations
export { MContainer } from './components/container/';
export { MHeading } from './components/heading';
// Components
export { MAccordion } from './components/accordion/';
export { MAutocomplete } from './components/autocomplete';
export { MBadge } from './components/badge/';
export { MBreadcrumb } from './components/breadcrumb/';
export { MButton } from './components/button/';
export { MCard } from './components/card/';
export { MCheckbox, MCheckboxGroup } from './components/checkbox/';
export { MDataTable, MDataTableHeader } from './components/datatable';
export { MField } from './components/field';
export { MDropdown } from './components/dropdown';
export { MFileUploader } from './components/fileuploader';
export { MFlag } from './components/flag';
export { MHero } from './components/hero';
export { MIcon } from './components/icon';
export { MLayer } from './components/layer';
export { MLink } from './components/link';
export { MListBox, MListBoxActions } from './components/listbox';
export { MLoader } from './components/loader';
export { MModal } from './components/modal';
export { MNotification } from './components/notification';
export { MOptionButton } from './components/optionbutton';
export { MOptionCard } from './components/optioncard';
export { MOptionGroup } from './components/optiongroup';
export { MOverlay, MOverlayLoader } from './components/overlay';
export { MPagination } from './components/pagination';
export { MPasswordInput } from './components/passwordinput';
export { MPhoneNumber } from './components/phonenumber';
export { MProgress } from './components/progressbar';
export { MQuantitySelector } from './components/quantityselector';
export { MRadio, MRadioGroup } from './components/radio';
export { MStarsInput, MStarsResult } from './components/ratingstars';
export { MSelect } from './components/select';
export { MStepper } from './components/stepper';
export { MTab } from './components/tabs';
export { MTag } from './components/tags';
export { MTextArea } from './components/textarea';
export { MTextInput } from './components/textinput';
export { MToggle } from './components/toggle';
export { MTooltip } from './components/tooltip';
