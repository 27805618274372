<template>
  <div class="mc-field">
    <label :for="id" class="mc-field__label">
      {{ label }}
      <span
        v-if="requirementText"
        class="mc-field__requirement"
        aria-hidden="true"
      >
        {{ requirementText }}
      </span>
    </label>
    <span v-if="helpId && helpText" :id="helpId" class="mc-field__help">
      {{ helpText }}
    </span>
    <slot />
    <span v-if="isInvalid" :id="errorId" class="mc-field__error-message">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'MField',
  provide: {
    cssFieldElementClass: 'mc-field__element',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    requirementText: {
      type: String,
      default: null,
    },
    helpId: {
      type: String,
      default: null,
    },
    helpText: {
      type: String,
      default: null,
    },
    errorId: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.fields';
</style>
