<template>
  <component
    :is="linkComponentName"
    v-bind="linkComponentProps"
    :href="router ? null : href && !disabled ? href : null"
    class="mc-link"
    :class="setClasses"
    @click="onClick"
  >
    <m-icon
      v-if="icon && iconPosition === 'left'"
      :class="`mc-link__icon mc-link__icon--${iconPosition}`"
      :name="icon"
    />
    <slot />
    <m-icon
      v-if="icon && iconPosition === 'right'"
      :class="`mc-link__icon mc-link__icon--${iconPosition}`"
      :name="icon"
    />
  </component>
</template>

<script>
import { checkThemeValue } from '../../utils/theme.validator';
import {
  responsiveModifierValidators,
  responsiveModifiers,
} from '../../utils/mozaicClasses';
import MIcon from '../icon/MIcon.vue';

export default {
  name: 'MLink',

  components: {
    MIcon,
  },

  props: {
    router: {
      type: Object,
      default: null,
    },
    href: {
      type: String,
      default: '#',
    },
    theme: {
      type: String,
      default: null,
      validator: (value) => checkThemeValue(value),
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) => responsiveModifierValidators(value, ['s', 'm']),
    },
    // Experimental solution waiting on this issue https://github.com/adeo/mozaic-vue/issues/52
    responsiveSizeModifiers: {
      type: Array,
      default: null,
      validator: (array) =>
        array.every((e) =>
          responsiveModifierValidators(e, [
            's@from-m',
            's@from-l',
            's@from-xl',
            's@from-xxl',
            'm@from-m',
            'm@from-l',
            'm@from-xl',
            'm@from-xxl',
          ])
        ),
    },
    icon: {
      type: String,
      default: null,
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    setClasses() {
      const classes = [];

      if (this.theme) {
        classes.push(`mc-link--${this.theme}`);
      }

      if (this.size) {
        responsiveModifiers('mc-link', this.size, classes);
      }

      if (this.disabled) {
        classes.push('is-disabled');
      }

      return classes;
    },
    linkComponentName() {
      const isLinkValid = this.href && !this.disabled;
      const routerComponent = this.router?.name ?? null;
      const defaultComponent = isLinkValid ? 'a' : 'span';

      return routerComponent ?? defaultComponent;
    },
    linkComponentProps() {
      return this.router?.props ?? {};
    },
  },
  methods: {
    onClick(event) {
      if (!this.disabled) {
        this.$emit('click', event);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.links';
</style>
