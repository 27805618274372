var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mc-phone-number",class:[
    { 'mc-phone-number--focused': _vm.isCountriesListOpened },
    _vm.cssFieldElementClass,
  ]},[(_vm.isCountriesListOpened)?_c('div',{staticClass:"mc-phone-number__overlay",on:{"click":_vm.toggleCountriesList}}):_vm._e(),_c('div',{staticClass:"mc-phone-number__dropdown",on:{"keydown":_vm.initKeyboardNav}},[_c('button',{staticClass:"mc-phone-number__button",attrs:{"id":"dropdown_country","type":"button","aria-haspopup":"listbox","aria-labelledby":"dropdown_country","aria-expanded":_vm.isCountriesListOpened},on:{"click":_vm.toggleCountriesList}},[(!_vm.withoutFlags)?_c('country-flag',{staticClass:"flag",attrs:{"country":_vm.selectedCountry.shortName,"size":"small"}}):_vm._e(),_c('span',{staticClass:"mc-phone-number__indicator"},[_vm._v(_vm._s(_vm.selectedCountry.isd))])],1),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCountriesListOpened),expression:"isCountriesListOpened"}],ref:"countriesList",staticClass:"mc-phone-number__list",attrs:{"id":"phone_number_list","tabindex":"-1","role":"listbox","aria-labelledby":"phone_number_list","aria-activedescendant":_vm.selectedCountry.isd}},_vm._l((_vm.countries),function(country){return _c('li',{key:country.isd,staticClass:"mc-phone-number__item",class:{
          'mc-phone-number__item--focused':
            country.isd === _vm.selectedCountry.isd,
        },attrs:{"role":"option","aria-selected":country.isd === _vm.selectedCountry.isd},on:{"click":function($event){return _vm.onChangeCountry(country)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onChangeCountry(country)}}},[(!_vm.withoutFlags)?_c('country-flag',{staticClass:"flag",attrs:{"country":country.shortName,"size":"small"}}):_vm._e(),_c('span',{staticClass:"mc-phone-number__country"},[_vm._v(_vm._s(country.name))]),_vm._v("   "),_c('span',[_vm._v(_vm._s(country.isd))])],1)}),0)]),_c('input',_vm._g({staticClass:"mc-phone-number__input mc-text-input mc-text-input--m mc-field__input",attrs:{"id":_vm.id,"type":"tel","name":"phone-number-input","placeholder":_vm.placeholder,"maxlength":_vm.maxlength},domProps:{"value":_vm.phoneNumber},on:{"blur":_vm.onChangePhoneNumber,"focus":(event) => (_vm.formatOnBlur ? _vm.cleanFormat(event) : null),"keyup":_vm.onKeyPressPhoneNumber,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;return ((event) => (_vm.formatOnBlur ? null : _vm.backspaceFunction(event))).apply(null, arguments)}}},_vm.$listeners))])
}
var staticRenderFns = []

export { render, staticRenderFns }