<template>
  <div class="mc-data-table" :class="getClasses">
    <slot />
    <div class="mc-data-table__body">
      <table aria-describedby="data" :class="getTableClasses">
        <thead>
          <tr v-if="!hideHeader">
            <th
              v-for="(header, index) in getHeaders"
              :key="`header-${index}`"
              :class="header.cssClass"
              scope="col"
            >
              <div class="header">
                <slot :name="`header.${header.dataFieldExpr}`" :header="header">
                  {{ header.caption }}
                </slot>
                <div
                  v-if="sorting.mode !== 'none' && header.allowSorting"
                  class="header__sort"
                  @click="!loading && onSortClick({ e: $event, header })"
                >
                  <m-icon
                    :name="'ArrowArrowTop16'"
                    :class="{ active: header.sortOrder === 'asc' }"
                  />
                  <m-icon
                    :name="'ArrowArrowBottom16'"
                    :class="{ active: header.sortOrder === 'desc' }"
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, rowIndex) in getSource"
            :key="item[dataKeyExpr]"
            :class="rowClasses(item)"
          >
            <td
              v-for="(header, index) in getHeaders"
              :key="`${index}-${getItemValue(item, dataKeyExpr)}-${
                header.dataFieldExpr
              }`"
              :class="header.cssClass"
              @click="
                allowRowClick && onRowClick({ event: $event, item: item })
              "
            >
              <slot
                :name="`item.${header.dataFieldExpr}`"
                :item="item"
                :index="rowIndex"
              >
                {{ getItemValue(item, header.dataFieldExpr) }}
              </slot>
            </td>
          </tr>
          <tr v-if="getSource.length == 0">
            <td :colspan="getHeaders.length">
              <slot name="no-data" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="pagingOptions.enabled && total != null"
      class="mc-data-table__footer"
    >
      <div class="mc-data-table__footer__item-per-page">
        <m-select
          :id="'itemPerPage'"
          :disabled="loading"
          :options="getPageSizes"
          :value="getPageValue"
          @change="onPageSizeChanged"
        >
          <template #text="{ option }">
            <slot name="pager.text" :pager="option">
              {{ option.text }}
            </slot>
          </template>
        </m-select>
      </div>
      <div
        v-if="pagingOptions.displayTotal"
        class="mc-data-table__footer__display-total-item"
      >
        <span class="strong">{{ getTotalStringCurrentCount }}</span> /
        <span class="strong">{{ total }}</span>
      </div>
      <div class="mc-data-table__footer__pagination">
        <m-pagination
          :disabled="loading"
          :length="getPagingSize"
          :page-label="pagingOptions.pageLabel"
          :value="pagingOptions.index"
          @on-update-page="onUpdatePage"
        >
          <template #text="{ option }">
            <slot name="paging.text" :paging="option">
              {{ option.text }}
            </slot>
          </template>
        </m-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import MIcon from '../icon/MIcon.vue';
import MPagination from '../pagination/MPagination.vue';
import MSelect from '../select/MSelect.vue';

import {
  getObjectValueByPath,
  isPromise,
  deepEqual,
  orderedArraySorted,
  parseClasses,
} from './helpers';

/** Map headers with default values. */
function headersMapped(headers) {
  return headers.map((header) => ({
    ...Column.defaultOptions,
    ...header,
    sortFieldExpr: header.sortFieldExpr
      ? header.sortFieldExpr
      : header.dataFieldExpr,
    sortOrder: header.sortOrder ? header.sortOrder : null,
  }));
}

/** Generate headers if there is no headers defined. */
function autoGenerateHeaders(data, headers) {
  if (headers.length === 0 && data.length > 0) {
    const cols = Object.keys(data[0]).map((key) => ({
      caption: key,
      dataFieldExpr: key,
    }));
    headersMapped(cols).forEach((col) => headers.push(col));
  }
}

/** Build options to manage request. */
function buildOptions(
  pagingEnabled,
  pagerValue,
  pagingIndex,
  pagingSize,
  sortedColmuns
) {
  const columnSorters = sortedColmuns.reduce(
    (acc, header) => ({
      ...acc,
      [header.sortFieldExpr ?? header.dataFieldExpr]: header.sortOrder,
    }),
    {}
  );

  const index = pagingIndex < pagingSize ? pagingIndex : pagingSize;

  return {
    sort: columnSorters,
    skip: pagingEnabled ? pagerValue * (index - 1) : null,
    take: pagingEnabled ? pagerValue : null,
  };
}

/** Caution : I have used object but we must speak about it to know if we use it or normalize field directly in MDataTable. */
const Pager = {
  defaultOptions: {
    sizes: [10, 20, 30],
    text: 'Show',
    value: 20,
  },
};

const Paging = {
  defaultOptions: {
    enabled: false,
    text: 'sur',
    index: 1,
  },
};

const Column = {
  defaultOptions: {
    caption: null,
    dataFieldExpr: null,
    sortFieldExpr: null,
    allowSorting: false,
    sortOrder: null,
  },
};

const Sorting = {
  defaultOptions: {
    /** Get or set sorting mode. Possible value : ['none', 'single', 'multiple']. */
    mode: 'multiple',
  },
};

export default {
  name: 'MDataTable',

  components: { MIcon, MSelect, MPagination },

  props: {
    /** Get or set the name of identity. */
    dataKeyExpr: {
      type: String,
      require: true,
      default: 'id',
    },

    /**
     * Get or set the headers informations.
     * @type {{ caption: string, dataFieldExpr: string, sortFieldExpr: string, allowSorting: boolean, sortOrder: 'asc' | 'desc' | null }[] }
     */
    headers: {
      type: Array,
      default: () => [],
    },

    /** Get or set the source of the data table.
     * The source can be an Array or a Promise or a function.
     * If the source is a function or a Promise, it's must be return or resolve an object like :
     *
     * @example
     * {
     *  data:Array = [...]  // The array of item
     *  total:Number =      // Total count of items
     * }
     */
    source: {
      type: [Array, Function, Promise],
      default: () => [],
    },

    /**
     * Get or set pager informations.
     * @type {{ sizes: number[], text: string, value: number }}
     */
    pager: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Get or set paging informations.
     * @type {{ enabled: boolean, text: string, index: number }}
     */
    paging: {
      type: Object,
      default: () => ({}),
    },

    /** Get or set if row can clickable. */
    allowRowClick: {
      type: Boolean,
      default: false,
    },

    /** Get or set if the headers are fixed. */
    fixedHeader: {
      type: Boolean,
      default: false,
    },

    /**
     * Get or set the sorting informations.
     * @type {{ mode:'none' | 'single' | 'multiple'}}
     */
    sorting: {
      type: Object,
      default: () => ({}),
    },

    /** Get or set if the headers are hide. */
    hideHeader: {
      type: Boolean,
      default: false,
    },

    itemClasses: {
      type: [String, Function, Object],
      default: '',
    },
  },

  data() {
    return {
      headersMapped: headersMapped(this.headers),
      sourceMapped: [],
      pagerOptions: null,
      pagingOptions: null,
      sortingOptions: null,
      total: null,
      data: null,
      loading: false,
      created: false,
    };
  },

  computed: {
    getSource() {
      return this.sourceMapped;
    },

    getHeaders() {
      return this.headersMapped;
    },

    getSortHeader() {
      return this.getHeaders.filter((header) => header.sortOrder != null);
    },

    getClasses() {
      return {
        'mc-data-table--fixed-header': this.fixedHeader,
      };
    },

    getTableClasses() {
      return {
        'no-data': this.getSource.length === 0,
      };
    },

    getPageSizes() {
      return this.pagerOptions.sizes.map((size) => ({
        value: size,
        text: `${this.pagerOptions.text} ${size}`,
      }));
    },

    getPageValue() {
      const { sizes, value } = this.pagerOptions;

      if (sizes.includes(value)) {
        return value;
      } else {
        return sizes[0];
      }
    },

    getPagingSize() {
      let size = 1;

      if (this.total) {
        size = Math.ceil(this.total / this.pagerOptions.value);
      } else {
        size = parseInt(this.pagingOptions.index);
      }

      return size;
    },

    getPagingIndex() {
      return this.pagingOptions.index < this.getPagingSize
        ? this.pagingOptions.index
        : this.getPagingSize;
    },

    getTotalStringCurrentCount() {
      const { skip, take } = buildOptions(
        this.pagingOptions.enabled,
        this.getPageValue,
        this.getPagingIndex,
        this.getPagingSize,
        this.getSortHeader
      );
      const on = skip + take;
      return `${skip + 1} - ${on >= this.total ? this.total : on}`;
    },
  },

  watch: {
    source: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (deepEqual(newValue, oldValue)) {
          return;
        }

        if (this.created) {
          await this.load();
        }
      },
    },

    headers: {
      deep: true,
      async handler(newValue) {
        this.headersMapped = headersMapped(newValue);

        this.$emit('headers-changed', newValue);

        if (this.created) {
          await this.load();
        }
      },
    },

    pager: {
      immediate: true,
      handler(newValue, oldValue) {
        if (deepEqual(newValue, oldValue)) {
          return;
        }
        this.pagerOptions = {
          ...Pager.defaultOptions,
          ...this.pager,
        };
      },
    },
    paging: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (deepEqual(newValue, oldValue)) {
          return;
        }

        this.pagingOptions = {
          ...Paging.defaultOptions,
          ...this.paging,
        };

        if (this.created) {
          await this.load();
        }
      },
    },
    sorting: {
      immediate: true,
      handler(newValue, oldValue) {
        if (deepEqual(newValue, oldValue)) {
          return;
        }

        this.sortingOptions = {
          ...Sorting.defaultOptions,
          ...this.sorting,
        };

        // Reset
        this.headersMapped.forEach((header) => (header.sortOrder = null));
      },
    },
  },

  mounted() {
    this.load();
    this.created = true;
  },

  methods: {
    getItemValue(item, key) {
      return getObjectValueByPath(item, key);
    },

    rowClasses(item) {
      const getClasses = parseClasses(this.itemClasses, item);

      return {
        'mc-data-table__body__row--clickable': this.allowRowClick,
        ...getClasses,
      };
    },

    /** Load data. */
    async load() {
      this.loading = true;

      if (this.source == null) {
        return;
      }

      try {
        const options = buildOptions(
          this.pagingOptions.enabled,
          this.getPageValue,
          this.getPagingIndex,
          this.getPagingSize,
          this.getSortHeader
        );

        if (Array.isArray(this.source)) {
          let data = this.source.slice();

          const sortedKeys = Object.keys(options.sort);

          if (sortedKeys.length > 0) {
            data = data.sort(
              orderedArraySorted(
                sortedKeys.map((key) => ({
                  fieldExpr: key,
                  sortOrder: options.sort[key],
                }))
              )
            );
          }

          if (options.skip != null && options.take != null) {
            data = data.splice(options.skip, options.take);
          }

          this.data = data;

          this.total = this.source.length;
        } else if (this.source instanceof Function) {
          const result = this.source(options);

          const { data, total } = isPromise(result) ? await result : result;

          this.data = data;
          this.total = total;
        }

        if (this.data) {
          autoGenerateHeaders(this.data, this.headersMapped);
          this.sourceMapped = this.data;
        }

        this.$emit('data-changed', this.sourceMapped);
      } finally {
        this.loading = false;
      }
    },

    async onUpdatePage(index) {
      this.pagingOptions.index = +index;

      await this.load();

      this.$emit('page-changed', index);
    },

    async onSortClick(e) {
      if (
        this.sortingOptions.mode === 'single' &&
        !this.getSortHeader.includes(e.header)
      ) {
        // Reinitialize sortOrder because there is only one sortable header
        this.headersMapped.forEach((header) => {
          header.sortOrder = null;
        });
      }

      switch (e.header.sortOrder) {
        case 'asc':
          e.header.sortOrder = 'desc';
          break;
        case 'desc':
          e.header.sortOrder = null;
          break;
        default:
          e.header.sortOrder = 'asc';
          break;
      }

      await this.load();

      this.$emit('sort-order-changed', e.header);
    },

    async onPageSizeChanged(value) {
      this.pagerOptions.value = +value;

      await this.load();

      this.$emit('page-size-changed', value);
    },

    onRowClick(e) {
      this.$emit('row-click', e);
    },
  },
};
</script>

<style lang="scss">
/* stylelint-disable */
$local-config: (
  font-path: '~@mozaic-ds/web-fonts',
);

@import 'settings-tools/_all-settings';

.mc-data-table {
  @include set-font-face();

  position: relative;
  background-color: $color-grey-000;
  padding: 2px 0;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;

  @include set-border-radius('m');

  &__body {
    overflow-x: scroll;
    height: 100%;

    & table {
      border-collapse: collapse;
      width: 100%;

      &.no-data {
        height: 100%;
        & tr {
          & td {
            text-align: center;
          }
        }
      }

      & thead {
        & tr {
          & th {
            height: 46px;
            background-color: $color-grey-000;
            padding: 0 1rem;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            text-align: left;
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
            white-space: nowrap;
            text-overflow: ellipsis;

            & .header {
              display: flex;
              align-items: center;

              &__sort {
                margin-left: 5px;
                position: relative;
                width: 24px;

                & svg {
                  margin-top: -50%;
                  opacity: 0.1;
                  position: absolute;
                  cursor: pointer;

                  &.active {
                    opacity: 1;
                  }

                  &:last-child {
                    top: 9px;
                  }
                }
              }
            }
          }
        }
      }

      & tbody {
        & tr {
          &:not(:last-child) {
            border-bottom: 1px solid $color-grey-300;
          }

          &.mc-data-table__body__row--clickable {
            cursor: pointer;

            &:hover {
              background-color: $color-grey-100;
              opacity: 0.8;
            }

            &:active {
              background-color: $color-grey-200;
              opacity: 0.8;
            }
          }

          & td {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            height: 40px;
            padding: 0 1rem;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &__footer {
    border-top: 1px solid $color-grey-300;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 1rem;

    @include set-border-radius('m', 'bottom');

    &__item-per-page {
      & select {
        width: auto;
        font-size: 0.875rem;
        line-height: 1.1428571;
        padding: calc(0.5rem - 1px) 2.25rem calc(0.5rem - 1px)
          calc(0.5rem - 1px);
      }
    }

    &__display-total-item {
      width: auto;
      min-width: 150px;
      padding: 0.5rem 2.25rem;
      font-size: 0.875rem;
      .strong {
        font-weight: bold;
      }
    }

    &__pagination {
      text-align: right;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      & .mc-pagination {
        .mc-pagination__button {
          min-width: 2rem;
          min-height: 2rem;

          .mc-pagination__button-icon {
            height: 1rem;
            width: 1rem;
          }
        }

        .mc-select {
          font-size: 0.875rem;
          line-height: 1.1428571;
          padding: calc(0.5rem - 1px) 2.25rem calc(0.5rem - 1px)
            calc(0.5rem - 1px);
        }
      }
    }
  }

  &.mc-data-table--fixed-header & {
    &__body {
      & table {
        overflow-y: auto;

        & thead {
          & tr {
            & th {
              position: sticky;
              top: 0;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
/* stylelint-enable */
</style>
