<template>
  <div :class="['mc-stars-input', setClasses]">
    <input
      :id="name + '0'"
      type="radio"
      :name="required ? name + '-unselected' : name"
      value="0"
      class="mc-stars-input__radio"
      checked
      :aria-label="unselectedText"
    />

    <template v-for="(rateId, index) in ratingValue">
      <input
        :id="name + rateId"
        :key="'ratingInput-' + rateId"
        type="radio"
        :name="name"
        :value="rateId"
        class="mc-stars-input__radio"
        :required="required"
        @click="$emit('star-clicked', index + 1)"
      />

      <label
        :key="'ratingLabel-' + rateId"
        :for="name + rateId"
        :title="ratingLabels[index]"
        class="mc-stars-input__label"
      >
        <span class="mc-stars-input__text">
          {{ ratingLabels[index] }}
        </span>
      </label>
    </template>
  </div>
</template>

<script>
import {
  responsiveModifiers,
  responsiveModifierValidators,
} from '../../utils/mozaicClasses';

const RATINGVALUE = 5;

export default {
  name: 'MStarsInput',

  inheritAttrs: false,

  props: {
    unselectedText: {
      type: String,
      default: 'No opinion given',
    },
    name: {
      type: String,
      default: 'rating',
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) =>
        responsiveModifierValidators(value, ['s', 'm', 'l', 'xl']),
    },
    ratingLabels: {
      type: Array,
      default() {
        return ['Very bad', 'Bad', 'Medium', 'Good', 'Excellent'];
      },
      validator: function (value) {
        return (
          value.length === RATINGVALUE &&
          value.every((item) => typeof item === 'string')
        );
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      id: null,
      ratingValue: RATINGVALUE,
    };
  },

  computed: {
    setClasses() {
      const classes = [];

      if (this.size) {
        responsiveModifiers('mc-stars-input', this.size, classes);
      }

      return classes;
    },
  },

  created() {
    this.id = this._uid;
  },

  methods: {
    incIndex(index) {
      return index++;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.stars-input';
</style>
