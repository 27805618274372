<template>
  <div
    class="mc-hero"
    :class="{
      'mc-hero--center': center,
      'mc-hero--with-content': $slots.default,
      'mc-hero--with-boxed': $slots.boxed,
    }"
  >
    <div :class="{ 'ml-container': true, 'ml-container--fluid': !fixed }">
      <div class="ml-flexy ml-flexy--gutter">
        <div class="mc-hero__main ml-flexy__col">
          <picture class="mc-hero__cover">
            <source
              v-for="(src, index) in srcSet"
              :key="index"
              :srcset="src.url"
              :media="`(max-width: ${src.width}px)`"
            />
            <img class="mc-hero__visual" :src="imgSrc" :alt="imgAlt" />
          </picture>
          <div class="mc-hero__header">
            <component
              :is="titleTag"
              class="
                mc-hero__title
                mt-heading
                mt-heading--lightest
                mt-heading--s
                mt-heading--underline
              "
              :class="{ 'mt-heading--center': center }"
            >
              {{ title }}
            </component>
          </div>
          <div v-if="$slots.default" class="mc-hero__content mt-body-m">
            <slot />
          </div>
        </div>
        <div v-if="$slots.boxed" class="mc-hero__boxed ml-flexy__col">
          <slot name="boxed" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MHero',
  props: {
    title: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      required: true,
    },
    titleTag: {
      type: String,
      default: 'h1',
      validator: (value) =>
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value),
    },
    imgAlt: {
      type: String,
      default: '',
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    srcSet: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'typography/_t.all-base-typography';
@import 'layouts/_l.container';
@import 'layouts/_l.flexy';
@import 'components/_c.hero';
</style>
