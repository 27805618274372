<template>
  <div class="mc-toggle" :class="setClasses">
    <input
      :id="id"
      :name="name"
      type="checkbox"
      class="mc-toggle__input"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
    />
    <label :for="id" class="mc-toggle__label">
      <span class="mc-toggle__content">
        {{ label }}
        <span
          v-if="stateLabelOff && stateLabelOn"
          class="mc-toggle__state"
          aria-hidden="true"
        >
          <span class="mc-toggle__off">{{ stateLabelOff }}</span>
          <span class="mc-toggle__on">{{ stateLabelOn }}</span>
        </span>
      </span>
    </label>
  </div>
</template>

<script>
import {
  responsiveModifierValidators,
  responsiveModifiers,
} from '../../utils/mozaicClasses';

export default {
  name: 'MToggle',

  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) => responsiveModifierValidators(value, ['s', 'm']),
    },
    // Experimental solution waiting on this issue https://github.com/adeo/mozaic-vue/issues/52
    responsiveSizeModifiers: {
      type: Array,
      default: null,
      validator: (array) =>
        array.every((e) =>
          responsiveModifierValidators(e, [
            's@from-m',
            's@from-l',
            's@from-xl',
            's@from-xxl',
            'm@from-m',
            'm@from-l',
            'm@from-xl',
            'm@from-xxl',
          ])
        ),
    },
    stateLabelOff: {
      type: String,
      default: null,
    },
    stateLabelOn: {
      type: String,
      default: null,
    },
  },

  computed: {
    setClasses() {
      const classes = [];

      if (this.size) {
        classes.push(`mc-toggle--${this.size}`);
      }

      if (
        this.responsiveSizeModifiers &&
        this.responsiveSizeModifiers.length > 0
      ) {
        for (const modifier of this.responsiveSizeModifiers) {
          responsiveModifiers('mc-toggle', modifier, classes);
        }
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/c.toggle';
</style>
