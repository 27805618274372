<template>
  <div
    class="mc-tooltip"
    :class="position ? `mc-tooltip--${position}` : ''"
    :aria-describedby="id"
  >
    <slot />

    <span :id="id" class="mc-tooltip__content" role="tooltip">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'MTooltip',

  inheritAttrs: false,

  props: {
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'right', 'bottom', 'left'].includes(value),
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'components/_c.tooltip';
</style>
