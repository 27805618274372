<template>
  <div class="mc-overlay" :class="{ 'is-visible': isVisible }">
    <!-- @slot Use this slot to insert a centered content inside the overlay -->
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MOverlay',

  props: {
    /**
     * Define if the overlay is visible or not
     * @values false, true
     */
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'components/c.overlay';
</style>
