<template>
  <component :is="hn" class="mt-heading" :class="classes">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'MHeading',

  props: {
    level: {
      type: String,
      default: '1',
      validator: (value) => ['1', '2', '3', '4'].includes(value),
    },
    size: {
      type: String,
      default: 'm',
      validator: (value) => ['s', 'm', 'l'].includes(value),
    },
    weight: {
      type: String,
      default: null,
      validator: (value) => ['light', 'semi-bold'].includes(value),
    },
    underline: {
      type: Boolean,
      default: false,
    },
    underlineColor: {
      type: String,
      default: null,
      validator: (value) =>
        ['primary-01-200', 'primary-02-600', 'primary-02-200'].includes(value),
    },
    lightest: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'center', 'right'].includes(value),
    },
    lineHeightXS: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hn() {
      return `h${this.level}`;
    },
    classes() {
      return {
        ['mt-heading--' + this.size]: this.size,
        ['mt-heading--' + this.weight]:
          this.weight === 'light' || this.weight === 'semi-bold',
        'mt-heading--lightest': this.lightest,
        'mt-heading--underline': this.underline,
        ['mt-heading--line-' + this.underlineColor]: this.underlineColor,
        ['mt-heading--' + this.align]: this.align,
        'mt-heading--line-height-xs': this.lineHeightXS,
      };
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/_all-settings';
@import 'typography/_t.headings';
</style>
