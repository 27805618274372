<template>
  <div>
    <div
      ref="modal"
      class="mc-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal_title"
      :aria-hidden="!open"
      @keyup.esc="closeModal()"
    >
      <div
        class="mc-modal__dialog"
        :class="{ 'is-open': open }"
        role="document"
      >
        <header class="mc-modal__header">
          <component
            :is="modalTitleTag"
            id="modal_title"
            class="mc-modal__title"
          >
            {{ modalTitle }}
          </component>
          <button
            v-if="displayCloseButton"
            ref="close"
            class="mc-modal__close"
            type="button"
            @click="closeModal()"
          >
            <span class="mc-modal__close-text">
              {{ closeButtonText }}
            </span>
          </button>
        </header>
        <main ref="body" class="mc-modal__body">
          <article ref="content" class="mc-modal__content">
            <component :is="titleTag" v-if="title" class="mc-modal__heading">
              {{ title }}
            </component>
            <div class="mt-body-m">
              <slot />
            </div>
          </article>
        </main>
        <footer v-if="$slots.footer" class="mc-modal__footer">
          <slot name="footer" />
        </footer>
      </div>
    </div>
    <keep-alive>
      <div
        class="mc-modal-overlay"
        :class="{ 'is-visible': open }"
        tabindex="-1"
        role="dialog"
      />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'MModal',

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    modalTitleTag: {
      type: String,
      default: 'h2',
    },
    title: {
      type: String,
      default: null,
    },
    titleTag: {
      type: String,
      default: 'h3',
    },
    closeButtonText: {
      type: String,
      default: 'Close',
      validator: (value) => value !== '',
    },
    displayCloseButton: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    open: {
      immediate: true,
      handler: function (isOpen) {
        this.$nextTick(() => {
          if (isOpen) {
            this.trapFocus();
            this.setFocusOnModal();
            this.setOverflow();
            this.$emit('modal-opened');
          } else {
            this.$emit('modal-closed');
          }
        });
      },
    },
  },

  methods: {
    trapFocus: function () {
      const modal = document.querySelector('.mc-modal');
      const focusableElements = modal.querySelectorAll(
        'a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      const firstFocusableElement = focusableElements[0]; // get first element to be focused inside modal
      const lastFocusableElement =
        focusableElements[focusableElements.length - 1];

      document.addEventListener('keydown', function (e) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus(); // add focus for the last focusable element
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus(); // add focus for the first focusable element
            e.preventDefault();
          }
        }
      });
    },

    closeModal() {
      this.$emit('update:open', false);
    },

    setOverflow: function () {
      const modal = this.$refs.modal;
      const bodyHeight = this.$refs.body.clientHeight;
      const contentHeight = this.$refs.content.clientHeight;

      if (contentHeight > bodyHeight) {
        modal.classList.add('mc-modal--overflow');
      }
    },

    setFocusOnModal() {
      const modal = this.$refs.modal;

      modal.focus();
    },
  },
};
</script>

<style lang="scss">
@import 'settings-tools/all-settings';
@import 'typography/_t.bodys';
@import 'components/_c.modal';
</style>
