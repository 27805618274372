import MOverlay from './MOverlay.vue';
import MOverlayLoader from './MOverlayLoader.vue';

MOverlay.install = function (Vue) {
  Vue.component(MOverlay.name, MOverlay);
};

MOverlayLoader.install = function (Vue) {
  Vue.component(MOverlayLoader.name, MOverlayLoader);
};

export { MOverlay, MOverlayLoader };
